<template>
  <div>
    <v-card-title class="pb-0">CASE AUDIT</v-card-title>
    <v-divider></v-divider>
    <v-container fluid style="width: 700px">
      <v-timeline :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item
          fill-dot
          class="white--text mb-6"
          color="success"
          large
        >
          <template v-slot:icon>
            <span>DAYS</span>
          </template>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.createdAt">
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.createdAt)) }}</span
          >

          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              CASEFILE CREATED
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              PENDING DOCUMENT APPLICATION / BRANCH ASSIGNING
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.createdBy) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.applyDocDate">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.createdAt).format(),
                  moment(caseState.casefile.applyDocDate).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.applyDocDate)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              DOCUMENTS APPLIED
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              PENDING BRANCH ASSIGNING
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.applyDocBy) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateOfReg">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.createdAt).format(),
                  moment(caseState.casefile.dateOfReg).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.dateOfReg)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              ASSIGNED TO BRANCH
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              PENDING BRANCH ACCEPTANCE
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.assignBranchBy) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateOfAdj">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateOfReg).format(),
                  moment(caseState.casefile.dateOfAdj).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.dateOfAdj)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              BRANCH ACCEPTED
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              ADJUSTER ASSIGNED
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.assignAdjBy) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateStartInv">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateOfAdj).format(),
                  moment(caseState.casefile.dateStartInv).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.dateStartInv)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              ADJUSTER ACCEPTED
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              STARTED INVESTIGATION
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.adjuster) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateEndInv">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateStartInv).format(),
                  moment(caseState.casefile.dateEndInv).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.dateEndInv)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              INVESTIGATION COMPLETE
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              REPORT SUBMITTED
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.adjuster) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateOfEdi">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateEndInv).format(),
                  moment(caseState.casefile.dateOfEdi).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.dateOfEdi)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              EDITOR ASSIGNED
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              PENDING EDITING
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.assignEdiBy) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateStartEdi">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateOfEdi).format(),
                  moment(caseState.casefile.dateStartEdi).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.dateStartEdi)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              EDITOR ACCEPTED
            </v-card-text>
            <v-card-text class="caption text-start py-0"> EDITING </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.editor) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateEndEdi">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateStartEdi).format(),
                  moment(caseState.casefile.dateEndEdi).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.dateEndEdi)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              EDITING COMPLETE
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              REPORT SUBMITTED
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.editor) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateOfApproval">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateEndEdi).format(),
                  moment(caseState.casefile.dateOfApproval).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.dateOfApproval)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              REPORT APPROVED
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              PENDING FORMATTING
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.approvedBy) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateAssignFormatting">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateOfApproval).format(),
                  moment(caseState.casefile.dateAssignFormatting).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{
              formatDate(new Date(caseState.casefile.dateAssignFormatting))
            }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              CLERK ASSIGNED
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              PENDING ACCEPTANCE
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.assignClerkBy) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateStartFormatting">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateAssignFormatting).format(),
                  moment(caseState.casefile.dateStartFormatting).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{
              formatDate(new Date(caseState.casefile.dateStartFormatting))
            }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              CLERK ACCEPTED
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              FORMATTING
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.clerkInCharge) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateEndFormatting">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateStartFormatting).format(),
                  moment(caseState.casefile.dateEndFormatting).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{
              formatDate(new Date(caseState.casefile.dateEndFormatting))
            }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              FORMATTING COMPLETE
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              REPORT SUBMITTED
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.clerkInCharge) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateFinal">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateEndFormatting).format(),
                  moment(caseState.casefile.dateFinal).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.dateFinal)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              INVOISING & FINALISATION
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              PENDING DISPATCH / CLOSURE
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.finalBy) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateDispatch">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateFinal).format(),
                  moment(caseState.casefile.dateDispatch).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.dateDispatch)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              DISPATCH INITIATED
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              PENDING DISPATCH
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.dispatchBy) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateClosed">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.dateFinal).format(),
                  moment(caseState.casefile.dateClosed).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.dateClosed)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 pb-0 text-start font-weight-black">
              CLOSED
            </v-card-text>
            <v-card-text class="caption text-start py-0">
              FILE CLOSED
            </v-card-text>
            <v-card-text class="caption text-start pt-0">
              - {{ getFilteredUserEmail(caseState.casefile.closedBy) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item v-if="caseState.casefile.dateOfCancel">
          <template v-slot:icon>
            <span
              v-if="dateDiff"
              class="white--text font-italic font-weight-black"
              >{{
                dateDiff(
                  moment(caseState.casefile.createdAt).format(),
                  moment(caseState.casefile.dateOfCancel).format()
                )
              }}
            </span>
          </template>
          <span slot="opposite">
            {{ formatDate(new Date(caseState.casefile.dateOfCancel)) }}</span
          >
          <v-card class="elevation-2">
            <v-card-text class="text-h6 text-start font-weight-black">
              CANCELLED
            </v-card-text>
            <v-card-text class="caption text-start">
              FILE CANCELLED
            </v-card-text>
            <v-card-text class="caption text-start">
              - {{ getFilteredUserEmail(caseState.casefile.cancelBy) }}
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "audit",
  props: ["members"],
  data: () => ({
    events: [],
    input: null,
    nonce: 0,
  }),

  computed: {
    ...mapState(["caseState"]),

    timeline() {
      return this.events.slice().reverse();
    },
  },

  methods: {
    moment: function (date) {
      return moment(date);
    },
    dateDiff(startDateString, endDateString) {
      let start = moment(startDateString);
      let end = moment(endDateString);
      let duration = moment.duration(end.diff(start));
      return Math.round(duration.asDays());
    },
    formatDate: function (date) {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY");
    },
    filterUser(item) {
      if (!item || !this.members) return [{ email: "N/A" }];
      const filtered = this.members.filter((member) => member.id === item);
      return filtered.length ? filtered : [{ email: "N/A" }];
    },
    getFilteredUserEmail(userId) {
      if (!userId || !this.members) return "N/A";
      const user = this.members.find((member) => member.id === userId);
      return user ? user.email : "N/A";
    },
  },
};
</script>
