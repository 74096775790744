<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-hover v-slot="{ hover }">
          <v-btn
            block
            height="80"
            class="success mr-1"
            :elevation="hover ? 8 : 2"
            v-on="{ ...tooltip }"
            :disabled="newPendDisabled"
            @click="assignBranch()"
          >
            <div class="d-flex flex-column align-center">
              <v-icon>mdi-file-cabinet</v-icon>
              <span class="action-text">ASSIGN BRANCH</span>
            </div>
          </v-btn>
        </v-hover>
      </template>
      <span>ASSIGN BRANCH</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->

    <v-dialog v-model="dialogAssignBranch" max-width="750px">
      <v-card>
        <v-card-title>
          <span class="text-h5">ASSIGN BRANCH</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-card width="100%">
                <v-card-title>Department</v-card-title>
                <v-col cols="12">
                  <v-select
                    v-model="caseState.casefile.refType"
                    :items="refType"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ maxHeight: '400' }"
                    hint="Select Department"
                    persistent-hint
                    label="Department"
                    @change="handleRefTypeChange"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span
                      >Department
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="caseState.casefile.subRefType"
                    :items="availableSubRefTypes"
                    item-text="subCode"
                    item-value="id"
                    no-data-text="There is no file-Classification option for selected department above"
                    :menu-props="{ maxHeight: '400' }"
                    label="File-Classification"
                    hint="Select File-Classification"
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-card>

              <v-col cols="12">
                <v-select
                  v-model="caseState.casefile.branch"
                  :items="branches"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ maxHeight: '400' }"
                  hint="Set the casefile branch"
                  persistent-hint
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Branch
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  v-model="caseState.casefile.insurer"
                  :items="insurer"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ maxHeight: '400' }"
                  hint="Set the casefile insurer"
                  persistent-hint
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Insurer
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="caseState.casefile.handler"
                  :items="filterHandler(caseState.casefile.insurer)"
                  item-text="name"
                  item-value="id"
                  no-data-text="There is no handler option for selected insurer above"
                  :menu-props="{ maxHeight: '400' }"
                  label="Claim Handler"
                  hint="Select Handler"
                  persistent-hint
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="caseState.casefile.claimNo"
                  item-text="Insurer's Reference"
                  :rules="[(v) => !!v || 'Insurers reference is required']"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Insurers
                    Reference
                  </template></v-text-field
                >
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <template>
                  <div>
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="caseState.casefile.dateOfAssign"
                          :value="computedDateFormattedMomentjs"
                          clearable
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="date = null"
                        >
                          <template #label>
                            <span class="red--text"><strong>* </strong></span
                            >Date Of Assignment
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="caseState.casefile.dateOfAssign"
                        :value="computedDateFormattedMomentjs"
                        @change="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </template>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="caseState.casefile.vehicleNo"
                  :rules="[(v) => !!v || 'Vehicle Number is required']"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Vehicle
                    Number
                  </template></v-text-field
                >
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <template>
                  <div>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="caseState.casefile.dateOfLoss"
                          :value="computedDateFormattedMomentjs"
                          clearable
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="date = null"
                        >
                          <template #label>
                            <span class="red--text"><strong>* </strong></span
                            >Date Of Loss
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="caseState.casefile.dateOfLoss"
                        :value="computedDateFormattedMomentjs"
                        @change="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </template>
              </v-col>

              <v-card width="100%">
                <v-card-title>Place Of Loss</v-card-title>
                <v-col cols="12">
                  <v-select
                    v-model="caseState.casefile.stateOfLoss"
                    :items="states"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ maxHeight: '400' }"
                    hint="Select State"
                    persistent-hint
                    label="State"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="caseState.casefile.placeOfLoss"
                    :items="filterRegions(caseState.casefile.stateOfLoss)"
                    item-text="name"
                    item-value="name"
                    no-data-text="There is no district option for selected state above"
                    :menu-props="{ maxHeight: '400' }"
                    label="Area Of Loss"
                    hint="Select District"
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-card>

              <v-col cols="12">
                <v-text-field
                  v-model="caseState.casefile.insuredName"
                  label="Insured Name"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="caseState.casefile.insComment"
                  label="Insurers Comment"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Insurers
                    Comment
                  </template>
                </v-textarea>
              </v-col>

              <v-col cols="12">
                <v-spacer></v-spacer>
                <v-btn
                  tile
                  dark
                  small
                  @click="closeAssignBranchDialog"
                  class="mt-3 mr-2 error"
                >
                  Cancel
                </v-btn>
                <v-btn
                  tile
                  small
                  :disabled="isAddFileButtonDisabled"
                  class="mt-3 success"
                  @click="assignBranchSubmit"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import MailDataService from "../../services/MailDataService";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "AssignBranch",
  props: [
    "branches",
    "branch",
    "management",
    "insurer",
    "refType",
    "subRefTypes",
    "members",
    "handlers",
    "states",
    "regions",
  ],
  components: {},

  data() {
    return {
      dialogAssignBranch: false,
      // currentEditor: "",
      menu: false,
      menu1: false,
    };
  },

  computed: {
    ...mapState(["caseState"]),

    isAddFileButtonDisabled() {
      return !(
        this.caseState.casefile.refType &&
        this.caseState.casefile.branch &&
        this.caseState.casefile.insurer &&
        this.caseState.casefile.claimNo &&
        this.caseState.casefile.vehicleNo &&
        this.caseState.casefile.dateOfLoss &&
        this.caseState.casefile.insComment
      );
    },

    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },

    newPendDisabled() {
      return !(
        (this.caseState.casefile.fileStatus == `NEW` ||
          this.caseState.casefile.fileStatus == `PEND`) &&
        this.management
      );
    },

    availableSubRefTypes() {
      // If there's an existing subRefType, show all options
      if (this.caseState.casefile.subRefType) {
        return this.subRefTypes;
      }
      // Otherwise filter based on selected refType
      return this.filtersubRefType(this.caseState.casefile.refType);
    },
  },

  watch: {
    assignBranch(val) {
      val || this.closeAssignBranchDialog();
    },
  },

  methods: {
    // moment: function (date) {
    //   return moment(date);
    // },
    filtersubRefType(item) {
      return this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === item
      );
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.insId === item);
    },
    filterBranchName(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch[0].name;
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },

    filterRegions(item) {
      return this.regions.filter((region) => region.branchId === item);
    },
    filterPicID(item) {
      if (!item) return "TBA";
      const filteredDept = this.refType.filter((dept) => dept.id === item);
      return filteredDept[0].picID;
    },

    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },

    findUserEmail(item) {
      const user = this.members.filter((member) => member.id === item);
      return user[0] ? user[0].email : "sys.admin@associatedadjusters.com";
      // return user.map((b) => b.email);
    },

    filterVehiclNo(item) {
      if (!item) return "TBA";
      return item.toLowerCase().replace(/\s/g, "");
    },
    assignBranch() {
      this.dialogAssignBranch = true;
    },

    assignBranchSubmit() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.vehicleNo = this.caseState.casefile.vehicleNo
          .toLowerCase()
          .replace(/\s/g, "")),
        (this.caseState.casefile.fileStatus = `BRAC`),
        (this.caseState.casefile.assignBranchBy =
          this.$store.state.auth.user.id),
        (this.caseState.casefile.dateOfReg = moment().format()) // dateOfReg -- need confirmation. Most probably dateOfReg = assignBranchDate
      )
        .then((response) => {
          // this.closeAdj();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })
        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            text:
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              toString(),
          });
        })

        .then(() => {
          // const assignedBranch = this.caseState.casefile.branch;
          const assignedDept = this.caseState.casefile.refType;
          var dataEmail = {
            id: this.caseState.casefile.id,
            refType: this.filterDept(this.caseState.casefile.refType)[0]
              .description,
            refTypeCode: this.filterDept(this.caseState.casefile.refType)[0]
              .name,
            subRefType: this.filterSubDept(
              this.caseState.casefile.subRefType
            )[0].subCodeDesc,
            subRefCode: this.filterSubDept(
              this.caseState.casefile.subRefType
            )[0].subCode,
            branch: this.filterBranchName(this.caseState.casefile.branch),
            branchCode: this.filterBranches(this.caseState.casefile.branch)[0]
              .brCode,
            insurer: this.filterIns(this.caseState.casefile.insurer)[0].name,
            vehicleNo: this.caseState.casefile.vehicleNo
              .toLowerCase()
              .replace(/\s/g, ""),
            // vehicleNo: this.caseState.casefile.vehicleNo,
            recipient: this.findUserEmail(this.filterPicID(assignedDept)),
            createdBy: this.$store.state.auth.user.id,
          };

          MailDataService.assignBranch(dataEmail)
            .then((response) => {
              this.closeAssignBranchDialog();
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "warning",
                text:
                  (response && response.data && response.data.message) ||
                  toString(),
              });
              // this.refreshList();
            })
            .catch((e) => {
              console.log(e);
            });
          this.dataEmail = "";
        })
        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            text:
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              toString(),
          });
        });
      this.closeAssignBranchDialog();
    },

    closeAssignBranchDialog() {
      this.dialogAssignBranch = false;
      this.$nextTick(() => {
        // this.caseState.casefile = Object.assign({});
        // this.caseState.casefile.fileStatus = "INC";
      });
    },

    handleRefTypeChange() {
      // Only reset subRefType if changing refType and there's no existing subRefType
      if (!this.caseState.casefile.subRefType) {
        this.caseState.casefile.subRefType = null;
      }
    },
  },
};
</script>
