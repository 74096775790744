<template>
  <div>
    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          :disabled="invoisingDisabled"
          class="success mr-1"
          fab
          x-small
          v-on="{ ...tooltip }"
          @click="completeInvoising()"
        >
          <v-icon>mdi-currency-usd</v-icon>
        </v-btn>
      </template>
      <span>INVOISING & FINALISATION</span>
    </v-tooltip> -->

    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-hover v-slot="{ hover }">
          <v-btn
            block
            height="80"
            class="success mr-1"
            :elevation="hover ? 8 : 2"
            v-on="{ ...tooltip }"
            :disabled="invoisingDisabled"
            @click="completeInvoising()"
          >
            <div class="d-flex flex-column align-center">
              <v-icon>mdi-currency-usd</v-icon>
              <span class="action-text">INVOISING & FINALISATION</span>
            </div>
          </v-btn>
        </v-hover>
      </template>
      <span>INVOISING & FINALISATION</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->
    <v-dialog persistent v-model="dialogCompleteInvoising" max-width="750px">
      <v-card class="text-center">
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span class="text-h5">INVOISING & FINALISATION</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <template>
                  <div>
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="invoice.date"
                          :value="computedDateFormattedMomentjs"
                          clearable
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="date = null"
                        >
                          <template #label>
                            <span class="red--text"><strong>* </strong></span
                            >Date Of Invoising
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="invoice.date"
                        :value="computedDateFormattedMomentjs"
                        @change="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </template>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="invoice.number"
                  :rules="[(v) => !!v || 'Invoice Number is required']"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Invoice
                    Number
                  </template></v-text-field
                >
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  type="number"
                  prefix="RM"
                  v-model.number="invoice.fee"
                  :rules="[(v) => !!v || 'Fee Note is required']"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Fee Note
                  </template></v-text-field
                >
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  type="number"
                  prefix="RM"
                  v-model.number="invoice.total"
                  :rules="[(v) => !!v || 'Total Invoice Amount is required']"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Total
                    Invoice Amount
                  </template></v-text-field
                >
              </v-col>

              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  tile
                  dark
                  small
                  @click="closeCompleteInvDialog"
                  class="mt-3 mr-2 error"
                >
                  Cancel
                </v-btn>
                <v-btn
                  tile
                  :disabled="isFileButtonDisabled"
                  small
                  class="mt-3 success"
                  @click="compInvoisingDone"
                  >Submit</v-btn
                >
              </v-card-actions>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import ClaimDataService from "../../services/ClaimDataService";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "Invoising",
  props: ["management"],
  components: {},

  data() {
    return {
      menu: false,
      dialogCompleteInvoising: false,
      rules: [
        (v) => !!v || "This field is required. Only Numbers accepted",
        // (v) => /^\d+$/.test(v) || "This field only accept numbers",
      ],
      invoice: {
        date: "",
        number: "",
        fee: "",
        total: "",
      },
    };
  },

  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },
    invoisingDisabled() {
      return !(this.caseState.casefile.fileStatus == `INFI` && this.management);
    },

    ...mapState(["caseState"]),

    isFileButtonDisabled() {
      return !(
        this.invoice.date &&
        this.invoice.number &&
        this.invoice.fee &&
        this.invoice.total
      );
    },
  },
  watch: {
    completeInvoising(val) {
      val || this.closeCompleteInvDialog();
    },
  },

  methods: {
    filterRegions(item) {
      return this.regions.filter((region) => region.branchId === item);
    },
    filtersubRefType(item) {
      return this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === item
      );
    },

    moment: function (date) {
      return moment(date);
    },

    filterHandler(item) {
      return this.handlers.filter((handler) => handler.id === item);
    },

    completeInvoising() {
      this.dialogCompleteInvoising = true;
    },

    compInvoisingDone() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.dateFinal = this.invoice.date),
        (this.caseState.casefile.invNo = this.invoice.number),
        (this.caseState.casefile.invFeeNote = this.invoice.fee),
        (this.caseState.casefile.invTotal = this.invoice.total),
        (this.caseState.casefile.fileStatus = `DISP`),
        (this.caseState.casefile.dateFinal = moment().format()),
        (this.caseState.casefile.finalBy = this.$store.state.auth.user.id)
        // (this.caseState.casefile.dateOfReg = moment().format())
      )
        .then((response) => {
          this.closeCompleteInvDialog();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })
        .catch((e) => {
          console.log(e);
        });

      var data = {
        caseId: this.caseState.casefile.id,
        isApproved: 0,
      };

      setTimeout(() => {
        ClaimDataService.updateAll(data.caseId, data)
          .then((response) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
          })
          .catch((e) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "error",
              text: e || toString(),
            });
          });
      }, 2500);

      this.closeCompleteInvDialog();
    },

    closeCompleteInvDialog() {
      this.dialogCompleteInvoising = false;
      this.$nextTick(() => {});
    },
  },
};
</script>
