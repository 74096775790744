<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          outlined
          class="mr-1"
          fab
          x-small
          v-on="{ ...tooltip }"
          v-if="management && caseState.casefile.fileStatus !== `CANC`"
          @click="editCase()"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>EDIT FILE</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->
    <v-dialog v-model="dialogEditCase" max-width="600px" persistent>
      <v-card tile>
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span class="text-h5">EDIT CASEFILE</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-tabs
                dark
                show-arrows
                v-model="editTab"
                class="mb-2"
                background-color="teal darken-3"
              >
                <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                <v-tab>General</v-tab>
                <v-tab>Insured</v-tab>
                <v-tab>Driver</v-tab>
                <v-tab>Insurer</v-tab>
                <v-tab>Solicitor</v-tab>
                <v-tab>Claimant</v-tab>
              </v-tabs>
            </v-row>
            <v-row>
              <v-tabs-items class="mt-1" v-model="editTab">
                <v-tab-item>
                  <v-row class="ma-1">
                    <v-card class="ma-1" tile>
                      <v-row class="ma-1">
                        <v-card-title>Department</v-card-title>

                        <v-col cols="12">
                          <v-select
                            dense
                            v-model="caseState.casefile.refType"
                            :items="refType"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            hint="Select Department"
                            persistent-hint
                            label="Department"
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span
                              >Department
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            dense
                            v-model="caseState.casefile.subRefType"
                            :items="
                              filtersubRefType(caseState.casefile.refType)
                            "
                            item-text="subCode"
                            item-value="id"
                            no-data-text="There is no file-Classification option for selected department above"
                            :menu-props="{ maxHeight: '400' }"
                            label="File-Classification"
                            hint="Select File-Classification"
                            persistent-hint
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-col cols="12">
                      <v-select
                        dense
                        v-model="caseState.casefile.branch"
                        :items="branches"
                        item-text="name"
                        item-value="id"
                        :menu-props="{ maxHeight: '400' }"
                        hint="Set the casefile branch"
                        persistent-hint
                        label="Branch"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        dense
                        v-model="caseState.casefile.insurer"
                        :items="insurer"
                        item-text="name"
                        item-value="id"
                        :menu-props="{ maxHeight: '400' }"
                        hint="Set the casefile insurer"
                        persistent-hint
                      >
                        <template #label>
                          <span class="red--text"><strong>* </strong></span
                          >Insurer
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        dense
                        v-model="caseState.casefile.handler"
                        :items="filterHandler(caseState.casefile.insurer)"
                        item-text="name"
                        item-value="id"
                        no-data-text="There is no handler option for selected insurer above"
                        :menu-props="{ maxHeight: '400' }"
                        label="Claim Handler"
                        hint="Select Handler"
                        persistent-hint
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        v-model="caseState.casefile.claimNo"
                        item-text="Insurer's Reference"
                        label="Insurers Reference Number"
                        :rules="[
                          (v) => !!v || 'Insurers reference is required',
                        ]"
                      >
                        <template #label>
                          <span class="red--text"><strong>* </strong></span
                          >Insurers Reference
                        </template></v-text-field
                      >
                    </v-col>

                    <v-col cols="12">
                      <template>
                        <div>
                          <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            max-width="290"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="ComputedDateOfAssignEdit"
                                persistent-hint
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="date = null"
                              >
                                <template #label>
                                  <span class="red--text"
                                    ><strong>* </strong></span
                                  >Date Of Assignment
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="caseState.casefile.dateOfAssign"
                              @change="menu2 = false"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </template>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        dense
                        v-model="caseState.casefile.vehicleNo"
                        label="Vehicle Number"
                        hint="Vehicle Number"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <template>
                        <div>
                          <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            max-width="290"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="ComputedDateOfLossEdit"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="date = null"
                              >
                                <template #label>
                                  <span class="red--text"
                                    ><strong>* </strong></span
                                  >Date Of Loss
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="caseState.casefile.dateOfLoss"
                              @change="menu3 = false"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </template>
                    </v-col>

                    <v-card tile>
                      <v-row class="ma-1">
                        <v-card-title>Place Of Loss</v-card-title>
                        <v-col cols="12">
                          <v-select
                            dense
                            v-model="caseState.casefile.stateOfLoss"
                            :items="states"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            hint="Select State"
                            persistent-hint
                            label="State"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            dense
                            v-model="caseState.casefile.placeOfLoss"
                            :items="
                              filterRegions(caseState.casefile.stateOfLoss)
                            "
                            item-text="name"
                            item-value="name"
                            no-data-text="There is no district option for selected state above"
                            :menu-props="{ maxHeight: '400' }"
                            label="Area Of Loss"
                            hint="Select District"
                            persistent-hint
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-card class="ma-1" tile>
                    <v-row class="ma-1">
                      <v-card-title>Insured Details</v-card-title>

                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.insuredName"
                          label="Insured Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.insuredIC"
                          label="Insured IC"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.insuredTel"
                          label="Insured Tel"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.insuredEmail"
                          label="Insured Email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card class="ma-1" tile>
                    <v-row class="ma-1">
                      <v-card-title>Insured Driver Details</v-card-title>

                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.insuredDriverName"
                          label="Insured Driver Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.insuredDriverIc"
                          label="Insured Driver IC"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.insuredDriverTel"
                          label="Insured Driver Tel"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.insuredDriverEmail"
                          label="Insured Driver Email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card tile class="ma-1">
                    <v-row class="ma-1">
                      <v-card-title>Insurer Details</v-card-title>

                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.policeReportNo"
                          label="Police Report Number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <template>
                          <div>
                            <v-menu
                              v-model="menu4"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="ComputedDateOfPoliceRepotDate"
                                  persistent-hint
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  @click:clear="date = null"
                                  label="Police Report Date"
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="caseState.casefile.policeReportDate"
                                @change="menu4 = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </template>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.policyNo"
                          label="Policy Number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <template>
                          <div>
                            <v-menu
                              v-model="menu5"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="ComputedDateOfCoverFrom"
                                  persistent-hint
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  @click:clear="date = null"
                                  label="Policy Cover From"
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="caseState.casefile.coverFrom"
                                @change="menu5 = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </template>
                      </v-col>
                      <v-col cols="12">
                        <template>
                          <div>
                            <v-menu
                              v-model="menu6"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="ComputedDateOfCoverTo"
                                  persistent-hint
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  @click:clear="date = null"
                                  label="Policy Cover To"
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="caseState.casefile.coverTo"
                                @change="menu6 = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </template>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="caseState.casefile.insComment"
                          label="Insurers Comment"
                        >
                          <template #label>
                            <span class="red--text"><strong>* </strong></span
                            >Insurers Comment
                          </template>
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card class="ma-1" tile>
                    <v-row class="ma-1">
                      <v-card-title>Insured Solicitor Details</v-card-title>

                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.solicitorName"
                          label="Insured Solicitor Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.solicitorTel"
                          label="Insured Solicitor Tel"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.solicitorEmail"
                          label="Insured Solicitor Email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.counselName"
                          label="Attending Counsel"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.counselTel"
                          label="Counsel Tel"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.counselEmail"
                          label="Counsel Email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card class="ma-1" tile>
                    <v-row class="ma-1">
                      <v-card-title>Third Party Claimant Details</v-card-title>

                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.thirdPartyClaimantName"
                          label="Third Party Claimant Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.thirdPartyClaimantIc"
                          label="Third Party Claimant IC"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.thirdPartyClaimantTel"
                          label="Third Party Claimant Tel"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="caseState.casefile.thirdPartyClaimantEmail"
                          label="Third Party Claimant Email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="
                            caseState.casefile.thirdPartyClaimantVehicleNo
                          "
                          label="Third Party Claimant Vehicle Number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="
                            caseState.casefile.thirdPartyClaimantVehicleModel
                          "
                          label="Third Party Claimant Vehicle Model"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="
                            caseState.casefile.thirdPartyClaimantSolicitor
                          "
                          label="Third Party Claimant Solicitor"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          v-model="
                            caseState.casefile.thirdPartyClaimantSolicitorEmail
                          "
                          label="Third Party Claimant Solicitor Email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
              <v-col cols="12" sm="6" md="6">
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  small
                  tile
                  @click="closeEditCase"
                  class="mt-3 mr-2"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :disabled="isEditFileButtonDisabled"
                  small
                  tile
                  class="success mt-3"
                  @click="editCasefile"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "editCase",
  props: [
    "management",
    "refType",
    "subRefTypes",
    "branches",
    "insurer",
    "handlers",
    "states",
    "regions",
    "stages",
  ],
  components: {},

  data() {
    return {
      dialogEditCase: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      editTab: null,
    };
  },

  computed: {
    ...mapState(["caseState"]),

    ComputedDateOfAssignEdit() {
      return this.formatDateMethod(this.caseState.casefile.dateOfAssign);
    },
    ComputedDateOfLossEdit() {
      return this.formatDateMethod(this.caseState.casefile.dateOfLoss);
    },
    ComputedDateOfPoliceRepotDate() {
      return this.formatDateMethod(this.caseState.casefile.policeReportDate);
    },
    ComputedDateOfCoverFrom() {
      return this.formatDateMethod(this.caseState.casefile.coverFrom);
    },
    ComputedDateOfCoverTo() {
      return this.formatDateMethod(this.caseState.casefile.coverTo);
    },
    isEditFileButtonDisabled() {
      return !(
        (
          this.caseState.casefile.refType &&
          this.caseState.casefile.insurer &&
          this.caseState.casefile.claimNo &&
          this.caseState.casefile.dateOfAssign &&
          this.caseState.casefile.dateOfLoss
        )
        // && this.caseState.casefile.insComment
      );
    },
  },
  watch: {
    editCase(val) {
      val || this.closeEditCase();
    },
  },

  methods: {
    moment: function (date) {
      return moment(date);
    },
    formatDateMethod(date) {
      if (!date) return null;
      return moment(date).format("DD/MM/YYYY");
    },
    yearOnly(value) {
      return moment(value).format("YY");
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filtersubRefType(item) {
      return this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === item
      );
    },

    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.insId === item);
    },
    filterRegions(item) {
      return this.regions.filter((region) => region.branchId === item);
    },

    editCase() {
      this.dialogEditCase = true;
    },

    editCasefile() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.vehicleNo = this.caseState.casefile.vehicleNo
          .toLowerCase()
          .replace(/\s/g, ""))
      )
        .then((response) => {
          this.closeEditCase();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeEditCase();
    },

    closeEditCase() {
      this.dialogEditCase = false;
      this.$nextTick(() => {});
    },
  },
};
</script>
