<template>
  <div>
    <!-- Only show if user is ADMIN or MANAGER (not BRANCHMANAGER) -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span v-on="on">
          <v-btn
            class="mx-1"
            fab
            x-small
            color="red lighten-2"
            dark
            v-bind="attrs"
            @click="showCancelCaseDialog"
            :disabled="!isRealManager"
          >
            <v-icon dark>mdi-file-document-remove-outline</v-icon>
          </v-btn>
        </span>
      </template>
      <span>Cancel Case</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->
    <v-dialog v-model="dialogCancelCase" max-width="500px">
      <v-card class="text-center">
        <v-card-title class="justify-center text-h5 grey lighten-2"
          >CANCEL ASSIGNMENT?</v-card-title
        >
        <v-card-text class="mt-3 pa-0">
          CASEFILE NUMBER: AA<span
            v-for="(dept, index) in filterDept(caseState.casefile.refType)"
            :key="index"
            >/{{ dept.name
            }}<span
              v-for="(subDept, index) in filtersubRefType(
                caseState.casefile.subRefType
              )"
              :key="index"
              >/{{ subDept.subCode }}</span
            ></span
          >/{{ caseState.casefile.id }}/{{
            yearOnly(caseState.casefile.createdAt)
          }}<span
            v-for="branch in filterBranches(caseState.casefile.branch)"
            :key="branch.id"
            >/{{ branch.brCode }}
          </span>
        </v-card-text>
        <v-col cols="12">
          <v-textarea
            solo
            dense
            placeholder="Reason"
            v-model="commentData.comment"
            class="subtitle-1"
          ></v-textarea>
        </v-col>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined small tile @click="closeCancelCase">No</v-btn>
          <v-btn
            small
            tile
            class="error"
            @click="cancelCaseConfirm"
            :disabled="isComment"
            >Yes</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import CommentDataService from "../../services/CommentDataService";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "cancelCase",
  props: {
    approvingManager: Boolean,
    casefile: Object,
    refType: Array,
    subRefTypes: Array,
    insurer: Array,
    branches: Array,
    management: Boolean,
  },
  components: {},

  data() {
    return {
      dialogCancelCase: false,
      commentData: {
        id: null,
        caseId: null,
        comment: "",
        createdBy: "",
      },
    };
  },

  computed: {
    isComment() {
      return !this.commentData.comment;
    },
    isRealManager() {
      return (
        this.$store.state.auth.user.roles.includes("ADMIN") ||
        this.$store.state.auth.user.roles.includes("MANAGER")
      );
    },
    ...mapState(["caseState"]),
  },
  watch: {
    cancelCase(val) {
      val || this.closeCancelCase();
    },
  },

  methods: {
    moment: function (date) {
      return moment(date);
    },
    yearOnly(value) {
      return moment(value).format("YY");
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filtersubRefType(item) {
      return this.subRefTypes.filter((subRefType) => subRefType.id === item);
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },

    // Add this method to show the cancel dialog
    showCancelCaseDialog() {
      this.dialogCancelCase = true;
    },

    cancelCase() {
      this.dialogCancelCase = true;
    },

    cancelCaseConfirm() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.fileStatus = `CANC`),
        (this.caseState.casefile.dateOfCancel = moment().format()),
        (this.caseState.casefile.cancelBy = this.$store.state.auth.user.id)
      )
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })
        .catch((e) => {
          console.log(e);
        });

      var data = {
        caseId: this.caseState.casefile.id,
        comment: this.commentData.comment,
        createdBy: this.$store.state.auth.user.id,
      };
      if (this.commentData.comment) {
        setTimeout(() => {
          CommentDataService.create(data)
            .then((response) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "success",
                text:
                  (response && response.data && response.data.message) ||
                  toString(),
              });
            })
            .catch((e) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "error",
                text: e || toString(),
              });
            });
        }, 2500);
      }

      this.closeCancelCase();
    },

    closeCancelCase() {
      this.dialogCancelCase = false;
      this.$nextTick(() => {});
    },
  },
};
</script>
