<template>
  <div class="detail-tab">
    <!-- Status Overview Cards -->
    <v-row class="mb-6">
      <v-col cols="12" sm="6" md="2">
        <v-card class="status-card" :class="getStatusColor">
          <v-card-text class="d-flex align-center">
            <v-icon size="48" color="white" class="mr-4"
              >mdi-file-document-outline</v-icon
            >
            <div>
              <div class="text-overline mb-1">Status</div>
              <div class="text-h6 white--text">
                {{
                  filterStages(caseState.casefile.fileStatus)[0]?.name || "N/A"
                }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-card class="status-card primary">
          <v-card-text class="d-flex align-center">
            <v-icon size="48" color="white" class="mr-4"
              >mdi-office-building</v-icon
            >
            <div>
              <div class="text-overline mb-1">Branch</div>
              <div class="text-h6 white--text">
                {{ filterBranchName(caseState.casefile.branch) }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-card class="status-card secondary">
          <v-card-text class="d-flex align-center">
            <v-icon size="48" color="white" class="mr-4"
              >mdi-account-tie</v-icon
            >
            <div>
              <div class="text-overline mb-1">Adjuster</div>
              <div class="text-h6 white--text">
                {{
                  filterAdj(caseState.casefile.adjuster)[0]?.username ||
                  "To be assigned"
                }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-card class="status-card info">
          <v-card-text class="d-flex align-center">
            <v-icon size="48" color="white" class="mr-4">mdi-pencil</v-icon>
            <div>
              <div class="text-overline mb-1">Editor</div>
              <div class="text-h6 white--text">
                {{
                  filterEditor(caseState.casefile.editor)[0]?.username ||
                  "To be assigned"
                }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-card class="status-card success">
          <v-card-text class="d-flex align-center">
            <v-icon size="48" color="white" class="mr-4"
              >mdi-check-decagram</v-icon
            >
            <div>
              <div class="text-overline mb-1">Approver</div>
              <div class="text-h6 white--text">
                {{
                  filterApprover(caseState.casefile.approvedBy)[0]?.username ||
                  "To be assigned"
                }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-card class="status-card deep-purple">
          <v-card-text class="d-flex align-center">
            <v-icon size="48" color="white" class="mr-4"
              >mdi-format-align-justify</v-icon
            >
            <div>
              <div class="text-overline mb-1">Clerk</div>
              <div class="text-h6 white--text">
                {{
                  filterClerk(caseState.casefile.clerkInCharge)[0]?.username ||
                  "To be assigned"
                }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Main Content -->
    <v-expansion-panels accordion flat hover multiple class="detail-panels">
      <!-- Case Information Panel -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex align-center justify-space-between w-100">
            <v-icon color="primary" size="24" class="mr-2"
              >mdi-file-document-outline</v-icon
            >
            <span class="panel-title">CASE INFORMATION</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-list dense>
                <v-list-item two-line>
                  <v-list-item-icon><v-icon>mdi-car</v-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>Vehicle No.</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ caseState.casefile.vehicleNo?.toUpperCase() }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-file-document</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Our Ref.</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      AA/{{
                        filterDept(caseState.casefile.refType)[0]?.name || ""
                      }}{{
                        filterSubDept(caseState.casefile.subRefType)[0]
                          ? "/" +
                            filterSubDept(caseState.casefile.subRefType)[0]
                              .subCode
                          : ""
                      }}/{{ caseState.casefile.id }}/{{
                        yearOnly(caseState.casefile.createdAt)
                      }}{{
                        filterBranches(caseState.casefile.branch)[0]?.brCode
                          ? "/" +
                            filterBranches(caseState.casefile.branch)[0].brCode
                          : ""
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-calendar</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Date of Loss</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ formatDate(caseState.casefile.dateOfLoss) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-map-marker</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Place of Loss</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ caseState.casefile.placeOfLoss }},
                      {{
                        filterStates(caseState.casefile.stateOfLoss)[0]?.name
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" md="6">
              <v-list dense>
                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon
                      >mdi-file-document-outline</v-icon
                    ></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Claim Type</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{
                        filterDept(caseState.casefile.refType)[0]?.name || "N/A"
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-calendar-check</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Assignment Date</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ formatDate(caseState.casefile.dateOfAssign) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-calendar-clock</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Acknowledgement Date</v-list-item-subtitle
                    >
                    <v-list-item-title class="font-weight-medium">
                      {{ formatDate(caseState.casefile.createdAt) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-account</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Case Creator</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{
                        filterUser(caseState.casefile.createdBy)[0]?.username ||
                        "N/A"
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-domain</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Creator Branch</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      <span
                        v-for="creator in filterUser(
                          caseState.casefile.createdBy
                        )"
                        :key="creator.id"
                      >
                        <span
                          v-for="(b, index) in creator.branches"
                          :key="index"
                          >{{ b.name }}</span
                        >
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Insured Details Panel -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex align-center justify-space-between w-100">
            <v-icon color="primary" size="24" class="mr-2">mdi-account</v-icon>
            <span class="panel-title">INSURED DETAILS</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-list dense>
                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-account</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Insured Name</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ caseState.casefile.insuredName }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-card-account-details</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>NRIC</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ caseState.casefile.insuredIC }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-phone</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Tel</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ caseState.casefile.insuredTel }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <v-list dense>
                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-email</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ caseState.casefile.insuredEmail }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Driver Details Panel -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex align-center justify-space-between w-100">
            <v-icon color="primary" size="24" class="mr-2">mdi-car-back</v-icon>
            <span class="panel-title">INSURED DRIVER DETAILS</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-list dense>
                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-account</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Driver Name</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ caseState.casefile.insuredDriverName }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-card-account-details</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>NRIC</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ caseState.casefile.insuredDriverIc }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <v-list dense>
                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-phone</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Tel</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ caseState.casefile.insuredDriverTel }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-email</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ caseState.casefile.insuredDriverEmail }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- Adding Police Report Details -->
            <v-col cols="12">
              <v-divider class="my-4"></v-divider>
              <div class="text-subtitle-2 mb-3">Police Report Information</div>
              <v-row dense>
                <v-col cols="12" md="4">
                  <v-list dense>
                    <v-list-item two-line>
                      <v-list-item-icon>
                        <v-icon>mdi-file-document-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle>Report No.</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-medium">
                          {{ caseState.casefile.policeReportNo }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col cols="12" md="4">
                  <v-list dense>
                    <v-list-item two-line>
                      <v-list-item-icon>
                        <v-icon>mdi-calendar</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle>Date</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-medium">
                          {{ formatDate(caseState.casefile.policeReportDate) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col cols="12" md="4">
                  <v-list dense>
                    <v-list-item two-line>
                      <v-list-item-icon>
                        <v-icon>mdi-clock-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle>Time</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-medium">
                          {{ formatTime(caseState.casefile.policeReportDate) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Insurance Details Panel -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex align-center justify-space-between w-100">
            <v-icon color="primary" size="24" class="mr-2"
              >mdi-shield-check</v-icon
            >
            <span class="panel-title">INSURER DETAILS</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-list dense>
                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-office-building</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Insurer</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ filterIns(caseState.casefile.insurer)[0]?.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-numeric</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Claim No.</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ caseState.casefile.claimNo }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-file-document</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Policy/Cover No</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ caseState.casefile.policyNo }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-calendar-range</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Coverage</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ formatDate(caseState.casefile.coverFrom) }} -
                      {{ formatDate(caseState.casefile.coverTo) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" md="6">
              <v-list dense>
                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-account-tie</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Claims Handler</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ filterHandler(caseState.casefile.handler)[0]?.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-phone</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Tel</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ filterHandler(caseState.casefile.handler)[0]?.offTel }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-cellphone</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Mobile</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ filterHandler(caseState.casefile.handler)[0]?.mobile }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-icon
                    ><v-icon>mdi-email</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-medium">
                      {{ filterHandler(caseState.casefile.handler)[0]?.email }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- Insurer's Remarks -->
            <v-col cols="12">
              <v-divider class="my-4"></v-divider>
              <v-list-item two-line>
                <v-list-item-icon
                  ><v-icon>mdi-comment-text</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>Insurer's Remarks</v-list-item-subtitle>
                  <v-list-item-title class="font-weight-medium">
                    {{ caseState.casefile.insComment }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<style scoped>
.detail-tab {
  padding: 16px;
}

.status-card {
  transition: transform 0.2s;
  border-radius: 8px !important;
}

.status-card:hover {
  transform: translateY(-4px);
}

.status-card .v-card__text {
  padding: 20px;
}

.text-overline {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.75rem;
  font-weight: 500;
}

.v-list-item {
  min-height: 56px !important;
}

.v-list-item__subtitle {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.v-list-item__title {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.v-expansion-panels {
  border-radius: 8px;
}

.v-expansion-panel {
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
}

.v-expansion-panel-header {
  padding: 16px;
  font-weight: 500;
}

.v-expansion-panel-content__wrap {
  padding: 16px;
}

.detail-panels {
  margin-bottom: 24px;
}

.status-card .text-h6 {
  font-size: 0.9rem !important;
  line-height: 1.2;
  word-break: break-word;
}

.text-subtitle-2 {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  font-weight: 500;
}

.panel-title {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.0892857143em;
  color: var(--v-primary-base);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-bottom: 4px;
}

.panel-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: currentColor;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.v-expansion-panel-header--active .panel-title::after {
  transform: scaleX(1);
}
</style>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "detailTab",
  props: [
    "adjusters",
    "editors",
    "clerks",
    "branches",
    "insurer",
    "handlers",
    "refType",
    "subRefTypes",
    "members",
    "stages",
    "states",
  ],
  components: {},

  data() {
    return {
      // tab: null,
    };
  },

  computed: {
    ...mapState(["caseState"]),

    getStatusColor() {
      const status = this.caseState.casefile.fileStatus;
      const colors = {
        NEW: "success",
        PEND: "warning",
        INVE: "info",
        COMP: "error",
        // Add more status colors as needed
      };
      return colors[status] || "grey";
    },
  },

  watch: {},

  methods: {
    moment: function (date) {
      return moment(date);
    },

    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },

    yearOnly(value) {
      // return moment(value).format("YYYY");
      return moment(value).format("YY");
    },

    filterStages(item) {
      return this.stages.filter((stage) => stage.stageCode === item);
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },

    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterAdj(item) {
      return this.adjusters.filter((adjuster) => adjuster.id === item);
    },
    filterEditor(item) {
      return this.editors.filter((editor) => editor.id === item);
    },
    filterApprover(item) {
      return this.members.filter((approver) => approver.id === item);
    },
    filterClerk(item) {
      return this.clerks.filter((clerk) => clerk.id === item);
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    filterStates(item) {
      return this.states.filter((state) => state.id === item);
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.id === item);
    },
    filterUser(item) {
      return this.members.filter((member) => member.id === item);
    },

    formatDate: function (date) {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY");
    },
    formatTime: function (date) {
      if (!date) return "";
      return moment(date).format("h:mm:ss a");
    },
    dateDiff(startDateString, endDateString) {
      // Method 01
      let start = moment(startDateString);
      let end = moment(endDateString);
      let duration = moment.duration(end.diff(start));
      return Math.round(duration.asDays());

      // Method 02
      // var start = moment(startDateString); //todays date
      // var end = moment(endDateString); // another date
      // var duration = moment.duration(end.diff(start));
      // var days = duration.asDays();
      // return Math.round(days);
    },
    refreshList() {
      // this.retrieveCasefiles(this.$route.params.id);
      // this.retrieveAllCase();
    },
    filterBranchName(item) {
      if (!item) return "To be assigned";
      const branch = this.branches.find((branch) => branch.id === item);
      return branch ? branch.name : "Unknown Branch";
    },
  },
  mounted() {
    // this.retrieveCasefiles(this.$route.params.id);
  },
};
</script>
