<template>
  <div>
    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          :disabled="approvalReportDisabled"
          class="success mr-1"
          fab
          x-small
          v-on="{ ...tooltip }"
          @click="approvalReport()"
        >
          <v-icon>mdi-tag-check</v-icon>
        </v-btn>
      </template>
      <span>APPROVE REPORT</span>
    </v-tooltip> -->

    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-hover v-slot="{ hover }">
          <v-btn
            block
            height="80"
            class="success mr-1"
            :elevation="hover ? 8 : 2"
            v-on="{ ...tooltip }"
            :disabled="approvalReportDisabled"
            @click="approvalReport()"
          >
            <div class="d-flex flex-column align-center">
              <v-icon>mdi-tag-check</v-icon>
              <span class="action-text">APPROVE REPORT</span>
            </div>
          </v-btn>
        </v-hover>
      </template>
      <span>APPROVE REPORT</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->
    <v-dialog persistent v-model="dialogApprovalReport" max-width="750px">
      <v-card>
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span class="text-h5">APPROVE REPORT</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-card width="100%" class="mt-3 mb-3 text-start" tile>
                <v-card-title>I APPROVE</v-card-title>
                <!-- <v-list subheader two-line flat dense>
                  <v-subheader class="text-h6">I confirm that:</v-subheader>

                  <v-list-item-group multiple>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            v-model="checkboxAppRep"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Claims</v-list-item-title>
                          <v-list-item-subtitle
                            >Claims verified and approved.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            v-model="checkboxAppRep1"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Documents</v-list-item-title>
                          <v-list-item-subtitle
                            >Report/ Documents verified.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list> -->
                <v-card-actions>
                  <v-btn
                    outlined
                    small
                    tile
                    @click="closeApprovalReportDialog"
                    class="mt-3 mr-2"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    tile
                    small
                    class="success mt-3"
                    @click="compApprovalReport"
                    >Submit</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "approvalReport",
  props: ["holdingManager"],
  components: {},

  data() {
    return {
      dialogApprovalReport: false,
      // checkboxAppRep: false,
      // checkboxAppRep1: false,
    };
  },

  computed: {
    approvalReportDisabled() {
      return !(
        this.caseState.casefile.fileStatus == `HOLD` && this.holdingManager
      );
    },

    ...mapState(["caseState"]),

    // isFileButtonDisabled() {
    //   return !(this.checkboxAppRep && this.checkboxAppRep1);
    // },
  },
  watch: {
    approvalReport(val) {
      val || this.closeApprovalReportDialog();
    },
  },

  methods: {
    // moment: function (date) {
    //   return moment(date);
    // },

    approvalReport() {
      this.dialogApprovalReport = true;
    },

    compApprovalReport() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        // (this.caseState.casefile.clerkInCharge = this.currentClerk),
        // (this.caseState.casefile.approvedBy = this.$store.state.auth.user.id),
        (this.caseState.casefile.fileStatus = `PFORM`),
        (this.caseState.casefile.dateOfApproval = moment().format())
      )
        .then((response) => {
          this.closeApprovalReportDialog();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeApprovalReportDialog();
    },

    closeApprovalReportDialog() {
      this.dialogApprovalReport = false;
      this.$nextTick(() => {});
    },
  },
};
</script>
