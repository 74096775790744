<template>
  <div>
    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          :disabled="reInvestigateDisabled"
          class="error mr-1"
          fab
          x-small
          v-on="{ ...tooltip }"
          @click="reInvestigate()"
        >
          <v-icon>mdi-steering-off</v-icon>
        </v-btn>
      </template>
      <span>RE-INVESTIGATE CASE</span>
    </v-tooltip> -->

    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-hover v-slot="{ hover }">
          <v-btn
            block
            height="80"
            class="error mr-1"
            :elevation="hover ? 8 : 2"
            v-on="{ ...tooltip }"
            :disabled="reInvestigateDisabled"
            @click="reInvestigate()"
          >
            <div class="d-flex flex-column align-center">
              <v-icon>mdi-steering-off</v-icon>
              <span class="action-text">RE-INVESTIGATE CASE</span>
            </div>
          </v-btn>
        </v-hover>
      </template>
      <span>RE-INVESTIGATE CASE</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->
    <v-dialog persistent v-model="dialogReInvestigate" max-width="750px">
      <v-card>
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span class="text-h5">RE-INVESTIGATE CASE</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-card width="100%" class="mt-3 mb-3 text-start">
                <v-card-title
                  >I advice to re-investigate the case.</v-card-title
                >
                <!-- <v-list subheader two-line flat dense>
                  <v-subheader class="text-h6">I confirm that:</v-subheader>

                  <v-list-item-group multiple>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            v-model="checkboxReInv"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Investigation</v-list-item-title>
                          <v-list-item-subtitle
                            >I advice to re-investigate the
                            case.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list> -->
                <v-divider inset></v-divider>
                <v-col cols="12">
                  <v-textarea
                    v-model="commentData.comment"
                    item-text="Add a remark"
                    label="Case remark"
                    :rules="[(v) => !!v || 'Remark is required']"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Remark
                    </template></v-textarea
                  >
                </v-col>
                <v-card-actions>
                  <v-btn
                    outlined
                    small
                    tile
                    @click="closeReInvestigateDialog"
                    class="mt-3 mr-2"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="isFileButtonDisabled"
                    small
                    tile
                    class="success mt-3"
                    @click="compApprovalReport"
                    >Submit</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import CommentDataService from "../../services/CommentDataService";

// import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "reInvestigate",
  props: ["holdingManager"],
  components: {},

  data() {
    return {
      dialogReInvestigate: false,
      // checkboxReInv: false,
      commentData: {
        id: null,
        caseId: null,
        // comment: "The case is send to branch for re-investigation",
        comment: null,
        createdBy: "",
      },
    };
  },

  computed: {
    reInvestigateDisabled() {
      return !(
        this.caseState.casefile.fileStatus == `HOLD` && this.holdingManager
      );
    },

    ...mapState(["caseState"]),

    isFileButtonDisabled() {
      return !(
        // this.checkboxReInv &&
        this.commentData.comment
      );
    },
  },
  watch: {
    reInvestigate(val) {
      val || this.closeReInvestigateDialog();
    },
  },

  methods: {
    // moment: function (date) {
    //   return moment(date);
    // },

    reInvestigate() {
      this.dialogReInvestigate = true;
    },

    compApprovalReport() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        // (this.caseState.casefile.clerkInCharge = this.currentClerk),
        (this.caseState.casefile.fileStatus = `BRAC`)
        // (this.caseState.casefile.dateOfApproval = moment().format())
      )
        .then((response) => {
          this.closeReInvestigateDialog();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })

        .then(() => {
          var data = {
            caseId: this.caseState.casefile.id,
            comment: this.commentData.comment,
            createdBy: this.$store.state.auth.user.id,
          };
          CommentDataService.create(data)
            // .then((response) => {
            //   this.$store.dispatch("setSnackbar", {
            //     showing: true,
            //     color: "success",
            //     text:
            //       (response && response.data && response.data.message) ||
            //       toString(),
            //   });
            //   this.closeFlag();
            //   // this.$router.push("/home");
            //   this.$router.go();
            // })
            .catch((e) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "error",
                text: e || toString(),
              });
            });
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeReInvestigateDialog();
    },

    closeReInvestigateDialog() {
      this.dialogReInvestigate = false;
      this.$nextTick(() => {});
    },
  },
};
</script>
