<template>
  <div>
    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          :disabled="assignAdjDisabled"
          class="success mr-1"
          fab
          x-small
          v-on="{ ...tooltip }"
          @click="assignAdj()"
        >
          <v-icon>mdi-account-plus-outline</v-icon>
        </v-btn>
      </template>
      <span>ACCEPT CASE & ASSIGN ADJUSTER</span>
    </v-tooltip> -->

    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-hover v-slot="{ hover }">
          <v-btn
            block
            height="80"
            class="success mr-1"
            :elevation="hover ? 8 : 2"
            v-on="{ ...tooltip }"
            :disabled="assignAdjDisabled"
            @click="assignAdj()"
          >
            <div class="d-flex flex-column align-center">
              <v-icon>mdi-account-plus-outline</v-icon>
              <span class="action-text">ACCEPT CASE & ASSIGN ADJUSTER</span>
            </div>
          </v-btn>
        </v-hover>
      </template>
      <span>ACCEPT CASE & ASSIGN ADJUSTER</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->

    <v-dialog v-model="dialogAdj" max-width="500px">
      <v-card class="text-center">
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span class="text-h5">ACCEPT CASE & ASSIGN ADJUSTER</span>
        </v-card-title>
        <v-card-text class="mt-3 pa-0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-text class="mt-3 pa-0">
                  CHOOSE BRANCH FOLLOWED BY ADJUSTER
                </v-card-text>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="branch.brCode"
                  :items="currentUserBranch()"
                  item-text="name"
                  item-value="brCode"
                  :menu-props="{ maxHeight: '400' }"
                  label="BRANCH"
                  hint="Select Branch"
                  persistent-hint
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="caseState.casefile.adjuster"
                  :items="filterAdjByBranch(branch.brCode)"
                  item-text="username"
                  item-value="id"
                  no-data-text="There is no Adjuster for the branch above"
                  :menu-props="{ maxHeight: '400' }"
                  label="ADJUSTERS"
                  hint="Assign Adjuster"
                  persistent-hint
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-spacer></v-spacer>
                <v-btn
                  tile
                  small
                  dark
                  @click="closeAdj"
                  class="mt-3 mr-2 error"
                >
                  Cancel
                </v-btn>
                <v-btn
                  tile
                  small
                  :disabled="assignAdjRule"
                  class="mt-3 success"
                  @click="saveAdjuster"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import MailDataService from "../../services/MailDataService";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "branchAccept",
  props: [
    "branches",
    // "branch",
    "management",
    "insurer",
    "refType",
    "subRefTypes",
    "members",
    "handlers",
    "states",
    "regions",
    "adjusters",
  ],
  components: {},

  data() {
    return {
      dialogAdj: false,
      // dialogBranchAccept: false,
      menu: false,
      menu1: false,
      branch: {
        name: "",
        brCode: "",
      },
    };
  },

  computed: {
    ...mapState(["caseState"]),

    isAddFileButtonDisabled() {
      return !(
        this.caseState.casefile.refType &&
        this.caseState.casefile.branch &&
        this.caseState.casefile.insurer &&
        this.caseState.casefile.claimNo &&
        this.caseState.casefile.vehicleNo &&
        this.caseState.casefile.dateOfLoss &&
        this.caseState.casefile.insComment
      );
    },

    assignAdjRule() {
      return !(
        // this.checkboxAcceptCase &&
        (this.branch.brCode && this.caseState.casefile.adjuster)
      );
    },

    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },

    assignAdjDisabled() {
      return !(
        (this.caseState.casefile.fileStatus == `BRAC` &&
          this.$store.state.auth.user.roles.includes("MANAGER")) ||
        this.$store.state.auth.user.roles.includes("ADMIN") ||
        this.$store.state.auth.user.roles.includes("CLERK") ||
        this.$store.state.auth.user.roles.includes("BRANCHMANAGER") ||
        this.$store.state.auth.user.roles.includes("BRANCHCLERK")
      );
    },
  },
  watch: {
    assignAdj(val) {
      val || this.closeAdj();
    },
  },

  methods: {
    currentUserBranch() {
      if (
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("ADMIN") ||
        this.$store.state.auth.user.roles.includes("CLERK")
      ) {
        return this.branches;
      } else
        return this.branches.filter(
          (x) => x.id === this.caseState.casefile.branch
        );
    },
    // moment: function (date) {
    //   return moment(date);
    // },
    filtersubRefType(item) {
      return this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === item
      );
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.insId === item);
    },
    filterBranchName(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch[0].name;
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },

    filterRegions(item) {
      return this.regions.filter((region) => region.branchId === item);
    },
    filterPicID(item) {
      if (!item) return "TBA";
      const filteredDept = this.refType.filter((dept) => dept.id === item);
      return filteredDept[0].picID;
    },

    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    findAdjEmail(item) {
      const adjuster = this.adjusters.filter(
        (adjuster) => adjuster.id === item
      );
      return (
        adjuster.map((b) => b.email) || "sys.admin@associatedadjusters.com"
      );
    },
    findUserEmail(item) {
      const user = this.members.filter((member) => member.id === item);
      return user[0] ? user[0].email : "sys.admin@associatedadjusters.com";
      // return user.map((b) => b.email);
    },

    filterAdjByBranch(item) {
      return this.adjusters.filter((r) =>
        r.branches.some((i) => i.brCode === item)
      );
    },

    assignAdj() {
      this.dialogAdj = true;
    },

    saveAdjuster() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.fileStatus = `ADAC`),
        (this.caseState.casefile.dateOfAdj = moment().format()),
        (this.caseState.casefile.assignAdjBy = this.$store.state.auth.user.id)
      )

        .then((response) => {
          this.closeAdj();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })
        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            text:
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              toString(),
          });
        })

        .then(() => {
          const assignedAdjuster = this.caseState.casefile.adjuster;
          var dataEmail = {
            id: this.caseState.casefile.id,
            refType: this.filterDept(this.caseState.casefile.refType)[0]
              .description,
            refTypeCode: this.filterDept(this.caseState.casefile.refType)[0]
              .name,
            subRefType: this.filterSubDept(
              this.caseState.casefile.subRefType
            )[0].subCodeDesc,
            subRefCode: this.filterSubDept(
              this.caseState.casefile.subRefType
            )[0].subCode,
            branch: this.filterBranchName(this.caseState.casefile.branch),
            branchCode: this.filterBranches(this.caseState.casefile.branch)[0]
              .brCode,
            insurer: this.filterIns(this.caseState.casefile.insurer)[0].name,
            vehicleNo: this.caseState.casefile.vehicleNo,
            recipient: this.findAdjEmail(assignedAdjuster),
            createdBy: this.$store.state.auth.user.id,
          };
          // console.log(dataEmail.vehicleNo);
          MailDataService.assignAdj(dataEmail)
            .then((response) => {
              this.closeAdj();
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "warning",
                text:
                  (response && response.data && response.data.message) ||
                  toString(),
              });
              // this.refreshList();
            })
            .catch((e) => {
              console.log(e);
            });
          this.dataEmail = "";
        })
        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            text:
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              toString(),
          });
        });
      this.closeAdj();
    },

    closeAdj() {
      this.dialogAdj = false;
      this.$nextTick(() => {
        // this.caseState.casefile.fileStatus = "NEW";
      });
    },
  },
};
</script>
