<template>
  <div>
    <!-- Access Denied Alert - Updated for better visibility and responsiveness -->
    <v-card
      v-if="permissionCheckComplete && accessDenied"
      class="mx-auto my-6 access-denied-card"
      max-width="650"
      elevation="8"
    >
      <v-banner class="banner-top" dark color="error" icon="mdi-alert-circle">
        <span class="text-h5 font-weight-bold">Access Denied</span>
      </v-banner>

      <v-card-text class="pa-5 text-center">
        <v-icon size="64" color="error" class="mb-4 access-icon"
          >mdi-shield-lock</v-icon
        >
        <h2 class="text-h5 mb-3 access-title">
          You don't have permission to view this case file
        </h2>
        <p class="subtitle-1 mb-4 access-message">
          The case you're trying to access (ID: {{ $route.params.id }}) may not
          exist or you don't have the necessary permissions to view it.
        </p>
        <p class="caption mb-6 grey--text access-hint">
          If you believe this is an error, please contact your system
          administrator.
        </p>
        <div class="text-center">
          <v-btn
            color="primary"
            x-large
            rounded
            elevation="2"
            @click="$router.push('/home')"
            class="px-7 return-button"
          >
            <v-icon left>mdi-home</v-icon>
            <span class="button-text">Return to Home</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <!-- Loading state -->
    <v-skeleton-loader
      v-else-if="loading"
      type="card, article, actions"
      class="mx-auto my-4"
    ></v-skeleton-loader>

    <!-- Main Content - only show if user has access and data is loaded -->
    <div v-else-if="permissionCheckComplete && !accessDenied && !loading">
      <!-- Header Section with Case Summary -->
      <v-sheet
        v-if="caseState.casefile.id && fileaccess"
        class="header-section px-6 py-4"
        dark
      >
        <v-row align="center">
          <v-col cols="12" sm="8">
            <div class="d-flex flex-wrap align-center">
              <h2 class="text-h5 font-weight-bold mb-0 text-break">
                AA/{{ filterDept(caseState.casefile.refType)[0]?.name || ""
                }}{{
                  filterSubDept(caseState.casefile.subRefType)[0]
                    ? "/" +
                      filterSubDept(caseState.casefile.subRefType)[0].subCode
                    : ""
                }}/{{ caseState.casefile.id }}/{{
                  yearOnly(caseState.casefile.createdAt)
                }}{{
                  filterBranches(caseState.casefile.branch)[0]?.brCode
                    ? "/" + filterBranches(caseState.casefile.branch)[0].brCode
                    : ""
                }}
              </h2>
              <v-chip
                small
                :color="getStatusColorClass"
                class="status-chip ml-3"
              >
                <span class="status-text">
                  {{
                    filterStages(caseState.casefile.fileStatus)[0]?.name ||
                    "N/A"
                  }}
                </span>
              </v-chip>
            </div>
            <div class="text-subtitle-1 mt-1 opacity-medium text-break">
              {{ filterIns(caseState.casefile.insurer)[0]?.name || "N/A" }}
              <span class="mx-2">•</span>
              {{ caseState.casefile.vehicleNo?.toUpperCase() }}
            </div>
            <!-- <v-chip> HOLDING MANAGER STATE: {{ this.holdingManager }} </v-chip> -->
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="text-right d-flex align-center justify-end flex-wrap"
          >
            <!-- Moved buttons -->
            <TransferCase
              :approvingManager="approvingManager"
              :casefile="caseState.casefile"
              :refType="refType"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :branches="branches"
              :members="members"
              :states="states"
              :regions="regions"
              class="mr-3"
            />
            <TransferAdj
              :casefile="caseState.casefile"
              :refType="refType"
              :branches="branches"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :management="management"
              :states="states"
              :regions="regions"
              :handlers="handlers"
              :members="members"
              :adjusters="adjusters"
              class="mr-3"
            />
            <CancelCase
              :casefile="caseState.casefile"
              :approvingManager="approvingManager"
              :refType="refType"
              :branches="branches"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :management="management"
              class="mr-3"
            />
            <EditCase
              :casefile="caseState.casefile"
              :management="management"
              :refType="refType"
              :branches="branches"
              :subRefTypes="subRefTypes"
              :handlers="handlers"
              :states="states"
              :regions="regions"
              :stages="stages"
              :insurer="insurer"
              class="mr-3"
            />
            <v-chip
              v-if="dateDiff"
              outlined
              small
              class="purple lighten-3 mr-3"
            >
              {{
                dateDiff(
                  moment(caseState.casefile.createdAt).format(),
                  moment().format()
                )
              }}
              Day(s)
            </v-chip>
            <acknowledgement
              :casefile="caseState.casefile"
              :refType="refType"
              :subRefTypes="subRefTypes"
              :insurer="insurer"
              :branches="branches"
              :states="states"
              class="mr-3"
            />
            <flag
              :casefile="caseState.casefile"
              class="d-inline-block flag-icon"
            />
          </v-col>
        </v-row>
      </v-sheet>

      <!-- Main Container -->
      <v-container fluid class="pa-0">
        <div v-if="caseState.casefile.id && fileaccess">
          <!-- Workflow Actions -->
          <v-card flat class="workflow-actions ma-4">
            <v-card-title class="workflow-title d-flex align-center pb-4">
              <v-icon large color="primary" class="mr-2"
                >mdi-ray-start-arrow</v-icon
              >
              <span class="title-text">Available Actions</span>
              <!-- <v-spacer></v-spacer>
              <v-chip :color="getStatusColorClass" class="status-chip" dark>
                {{
                  filterStages(caseState.casefile.fileStatus)[0]?.name || "N/A"
                }}
              </v-chip> -->
            </v-card-title>

            <v-card-text class="action-buttons pa-6">
              <v-row justify="center" align="start">
                <!-- Initial Processing -->
                <ApplyDoc
                  v-if="hasAvailableActions.showApplyDoc"
                  ref="applyDoc"
                  :casefile="caseState.casefile"
                  :refType="refType"
                  :branches="branches"
                  :subRefTypes="subRefTypes"
                  :insurer="insurer"
                  :management="management"
                  class="action-wrapper"
                />

                <AssignBranch
                  v-if="hasAvailableActions.showAssignBranch"
                  ref="assignBranch"
                  :casefile="caseState.casefile"
                  :refType="refType"
                  :branches="branches"
                  :subRefTypes="subRefTypes"
                  :insurer="insurer"
                  :management="management"
                  :states="states"
                  :regions="regions"
                  :handlers="handlers"
                  :members="members"
                  class="action-wrapper"
                />

                <BranchAccept
                  v-if="hasAvailableActions.showBranchAccept"
                  ref="branchAccept"
                  :casefile="caseState.casefile"
                  :refType="refType"
                  :branches="branches"
                  :subRefTypes="subRefTypes"
                  :insurer="insurer"
                  :management="management"
                  :states="states"
                  :regions="regions"
                  :handlers="handlers"
                  :members="members"
                  :adjusters="adjusters"
                  class="action-wrapper"
                />

                <!-- Investigation -->
                <v-col
                  v-if="hasAvailableActions.showAcceptAssignment"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      block
                      height="80"
                      class="action-button success"
                      :elevation="hover ? 8 : 2"
                      @click="acceptCase"
                    >
                      <div class="d-flex flex-column align-center">
                        <v-icon size="32" class="mb-1"
                          >mdi-account-check</v-icon
                        >
                        <span class="action-text">Accept Assignment</span>
                      </div>
                    </v-btn>
                  </v-hover>
                </v-col>

                <CompleteInvestigation
                  v-if="hasAvailableActions.showCompleteInvestigation"
                  ref="completeInvestigation"
                  :casefile="caseState.casefile"
                  :claims="claims"
                  :refType="refType"
                  :branches="branches"
                  :branch="branch"
                  :subRefTypes="subRefTypes"
                  :insurer="insurer"
                  :clerks="clerks"
                  :members="members"
                  class="action-wrapper"
                />

                <!-- Review -->
                <AssignEditor
                  v-if="hasAvailableActions.showAssignEditor"
                  ref="assignEditor"
                  :casefile="caseState.casefile"
                  :branches="branches"
                  :branch="branch"
                  :management="management"
                  :editors="editors"
                  :refType="refType"
                  :subRefTypes="subRefTypes"
                  :insurer="insurer"
                  :members="members"
                  class="action-wrapper"
                />

                <AcceptEditing
                  v-if="hasAvailableActions.showAcceptEditing"
                  ref="acceptEditing"
                  :casefile="caseState.casefile"
                  class="action-wrapper"
                />

                <CompleteEditing
                  v-if="hasAvailableActions.showCompleteEditing"
                  ref="completeEditing"
                  :casefile="caseState.casefile"
                  :refType="refType"
                  :branches="branches"
                  :branch="branch"
                  :subRefTypes="subRefTypes"
                  :insurer="insurer"
                  :clerks="clerks"
                  :members="members"
                  class="action-wrapper"
                />

                <!-- Management -->
                <HoldApproval
                  v-if="hasAvailableActions.showHoldApproval"
                  ref="holdApproval"
                  :approvingManager="approvingManager"
                  class="action-wrapper"
                />

                <ApprovalReport
                  v-if="hasAvailableActions.showApprovalReport"
                  ref="approvalReport"
                  :holdingManager="holdingManager"
                  class="action-wrapper"
                />

                <ReInvestigate
                  v-if="hasAvailableActions.showReInvestigate"
                  ref="reInvestigate"
                  :holdingManager="holdingManager"
                  class="action-wrapper"
                />

                <ReEdit
                  v-if="hasAvailableActions.showReEdit"
                  ref="reEdit"
                  :holdingManager="holdingManager"
                  class="action-wrapper"
                />

                <!-- Final Processing -->
                <AssignClerk
                  v-if="hasAvailableActions.showAssignClerk"
                  ref="assignClerk"
                  :casefile="caseState.casefile"
                  :branches="branches"
                  :branch="branch"
                  :refType="refType"
                  :subRefTypes="subRefTypes"
                  :insurer="insurer"
                  :members="members"
                  :management="management"
                  :clerks="clerks"
                  class="action-wrapper"
                />

                <AcceptFormatting
                  v-if="hasAvailableActions.showAcceptFormatting"
                  ref="acceptFormatting"
                  :casefile="caseState.casefile"
                  class="action-wrapper"
                />

                <CompleteFormatting
                  v-if="hasAvailableActions.showCompleteFormatting"
                  ref="completeFormatting"
                  :casefile="caseState.casefile"
                  class="action-wrapper"
                />

                <!-- Completion -->
                <Invoising
                  v-if="hasAvailableActions.showInvoising"
                  ref="invoising"
                  :management="management"
                  class="action-wrapper"
                />

                <Dispatch
                  v-if="hasAvailableActions.showDispatch"
                  ref="dispatch"
                  :management="management"
                  class="action-wrapper"
                />

                <DoneDispatch
                  v-if="hasAvailableActions.showDoneDispatch"
                  ref="doneDispatch"
                  :management="management"
                  class="action-wrapper"
                />

                <Close
                  v-if="hasAvailableActions.showClose"
                  ref="close"
                  :management="management"
                  class="action-wrapper"
                />

                <!-- No Actions Message -->
                <v-col
                  cols="12"
                  v-if="!Object.values(hasAvailableActions).some((v) => v)"
                >
                  <div class="no-actions text-center pa-6">
                    <v-icon size="48" color="grey lighten-1"
                      >mdi-timer-sand</v-icon
                    >
                    <h3 class="mt-4 grey--text text--darken-1">
                      Awaiting Next Action
                    </h3>
                    <p class="subtitle-1 grey--text">
                      Current Stage:
                      {{
                        filterStages(caseState.casefile.fileStatus)[0]?.name ||
                        "N/A"
                      }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Tab Tittles -->

          <v-row>
            <v-tabs grow v-model="tab" class="mb-5">
              <v-tabs-slider color="green" style="height: 5px"></v-tabs-slider>
              <v-tab>Details</v-tab>
              <v-tab>
                Documents
                <v-badge
                  :content="documentCount || '0'"
                  color="success"
                  offset-x="5"
                  offset-y="1"
                ></v-badge>
              </v-tab>
              <v-tab>
                Comments
                <v-badge
                  :content="commentCount || '0'"
                  color="warning"
                  offset-x="5"
                  offset-y="1"
                ></v-badge>
              </v-tab>
              <v-tab>
                History
                <!-- <v-badge
                  :content="historyCount || '0'"
                  color="orange"
                  offset-x="5"
                  offset-y="1"
                ></v-badge> -->
              </v-tab>
              <v-tab>Audit</v-tab>
              <v-tab>Claims</v-tab>
            </v-tabs>
          </v-row>

          <!-- Tab Items -->
          <v-tabs-items class="mt-1" v-model="tab">
            <v-tab-item>
              <DetailTab
                :adjusters="adjusters"
                :members="members"
                :editors="editors"
                :clerks="clerks"
                :branches="branches"
                :insurer="insurer"
                :handlers="handlers"
                :refType="refType"
                :subRefTypes="subRefTypes"
                :stages="stages"
                :states="states"
              />
            </v-tab-item>
            <v-tab-item>
              <Documents
                :casefile="caseState.casefile"
                :preloadedDocs="documents"
                :loading="documentsLoading"
              />
            </v-tab-item>
            <v-tab-item>
              <!-- <v-card flat id="comments" class="mt-5 my-2 pa-2"> -->
              <!-- <v-card-title class="pb-0">COMMENTS</v-card-title>
                <v-divider></v-divider> -->
              <comments
                :casefile="caseState.casefile"
                :preloadedComments="comments"
                :loading="commentsLoading"
                @comment-added="handleCommentAdded"
                @comment-deleted="handleCommentDeleted"
              />
              <!-- </v-card> -->
            </v-tab-item>
            <v-tab-item>
              <History
                :branches="branches"
                :refType="refType"
                :subRefTypes="subRefTypes"
                :stages="stages"
                :members="members"
                :insurer="insurer"
                :states="states"
                :handlers="handlers"
              />
            </v-tab-item>
            <v-tab-item>
              <Audit :members="members" />
            </v-tab-item>
            <v-tab-item>
              <Claims
                :branches="branches"
                :refType="refType"
                :subRefTypes="subRefTypes"
                :members="members"
              />
            </v-tab-item>
          </v-tabs-items>

          <v-row>
            <v-col cols="12">
              <!-- Action Row -->
              <v-row no-gutters class="mt-2">
                <v-col cols="12" justify="center" align="center">
                  <v-dialog v-model="dialogAcceptCase" max-width="500px">
                    <v-card class="text-center">
                      <v-card-title
                        class="justify-center text-h5 grey lighten-2"
                        >ACCEPT ASSIGNMENT?</v-card-title
                      >
                      <v-card-text class="mt-3 pa-0">
                        CASEFILE NUMBER: AA<span
                          v-for="(dept, index) in filterDept(
                            caseState.casefile.refType
                          )"
                          :key="index"
                          >/{{ dept.name
                          }}<span
                            v-for="(subDept, index) in filterSubDept(
                              caseState.casefile.subRefType
                            )"
                            :key="'sub-' + index"
                            >/{{ subDept.subCode }}</span
                          ></span
                        >/{{ caseState.casefile.id }}/{{
                          yearOnly(caseState.casefile.createdAt)
                        }}<span
                          v-for="branch in filterBranches(
                            caseState.casefile.branch
                          )"
                          :key="branch.id"
                          >/{{ branch.brCode }}</span
                        >
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          tile
                          small
                          dark
                          class="mt-3 mr-2 error"
                          @click="closeAcceptCase"
                          >Cancel</v-btn
                        >
                        <v-btn
                          tile
                          small
                          @click="acceptCaseConfirm"
                          class="mt-3 mr-2 success"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <!-- <div v-else>
          <v-card flat class="pa-0 ma-auto">
            <h1>
              You might not have enough permission to view this content? Try
              refreshing .....
            </h1>
          </v-card>
        </div> -->
      </v-container>
    </div>
  </div>
</template>

<script>
import AcceptEditing from "../../components/detailView/acceptEditing.vue";
import AcceptFormatting from "../../components/detailView/acceptFormatting.vue";
import Acknowledgement from "../../components/pdf/acknowledgement.vue";
import ApplyDoc from "../../components/detailView/applyDoc.vue";
import ApprovalReport from "../../components/detailView/approvalReport.vue";
import AssignBranch from "../../components/detailView/assignBranch.vue";
import AssignClerk from "../../components/detailView/assignClerk.vue";
import AssignEditor from "../../components/detailView/assignEditor.vue";
import Audit from "../../components/detailView/audit.vue";
import BranchAccept from "../../components/detailView/branchAccept.vue";
import BranchDataService from "../../services/BranchDataService";
import CancelCase from "../../components/detailView/cancelCase.vue";
import CasefileDataService from "../../services/CasefileDataService";
import Close from "../../components/detailView/close.vue";
import Comments from "../../components/comment/comments.vue";
import CompleteEditing from "../../components/detailView/completeEditing.vue";
import CompleteFormatting from "../../components/detailView/completeFormatting.vue";
import CompleteInvestigation from "../../components/detailView/completeInvestigation.vue";
import DeptDataService from "../../services/DeptDataService";
import DetailTab from "../../components/detailView/detailTab.vue";
import Dispatch from "../../components/detailView/dispatch.vue";
import Documents from "../../components/uploads/documents.vue";
import DoneDispatch from "../../components/detailView/doneDispatch.vue";
import EditCase from "../../components/detailView/editCase.vue";
import HandlerDataService from "../../services/HandlerDataService";
import History from "../../components/detailView/history.vue";
import HoldApproval from "../../components/detailView/holdApproval.vue";
import InsDataService from "../../services/InsDataService";
import Invoising from "../../components/detailView/invoising.vue";
import MemberDataService from "../../services/MemberDataService";
import ReEdit from "../../components/detailView/reEdit.vue";
import RegionDataService from "../../services/RegionDataService";
import ReInvestigate from "../../components/detailView/reInvestigate.vue";
import StagesDataService from "../../services/StageDataService";
import StateDataService from "../../services/StateDataService";
import SubRefTypeDataService from "../../services/SubDeptDataService";
import TransferAdj from "../../components/detailView/transferAdj.vue";
import TransferCase from "../../components/detailView/transferCase.vue";
import DocsDataService from "../../services/DocsDataService";
import CommentsDataService from "../../services/CommentDataService";
// import CasesDataService from "../../services/CasefileDataService";

import ClaimDataService from "../../services/ClaimDataService";

import moment from "moment";
import Flag from "../../components/flag/flag";
import { mapState } from "vuex";
import Claims from "../../components/detailView/detailClaims.vue";
// import MailDataService from "../../services/MailDataService";
import { ref } from "vue";

export default {
  name: "vuexFile",
  components: {
    Acknowledgement,
    Documents,
    Comments,
    Flag,
    ApplyDoc,
    AssignBranch,
    BranchAccept,
    CompleteInvestigation,
    AssignEditor,
    AcceptEditing,
    CompleteEditing,
    CancelCase,
    TransferCase,
    TransferAdj,
    EditCase,
    HoldApproval,
    ApprovalReport,
    ReInvestigate,
    ReEdit,
    AssignClerk,
    AcceptFormatting,
    CompleteFormatting,
    Invoising,
    Dispatch,
    DoneDispatch,
    Close,
    Audit,
    DetailTab,
    History,
    Claims,
  },

  data() {
    return {
      overlay: true,
      tab: null,

      fileaccess: false,
      management: false,
      newCase: false,
      claims: [],
      stages: [],
      adjusters: [],
      editors: [],
      clerks: [],
      members: [],
      branches: [],
      regions: [],
      refType: [],
      subRefTypes: [],
      insurer: [],
      handlers: [],
      branch: {
        name: "",
        brCode: "",
      },
      states: [],

      // dialogAdj: false,
      // dialogAssignBranch: false,
      dialogAcceptCase: false,

      acknowledgement: false,
      menu: false,
      menu1: false,
      // checkboxAcceptCase: false,
      documentCount: 0,
      commentCount: 0,
      historyCount: 0,
      documents: ref([]),
      documentsLoading: ref(false),
      comments: ref([]),
      commentsLoading: ref(false),
      accessDenied: false,
      loading: true,
      permissionCheckComplete: false, // Add this to track permission check status
    };
  },

  computed: {
    ...mapState(["caseState"]),

    holdingManager() {
      return (
        this.caseState.casefile.approvedBy === this.$store.state.auth.user.id
      );
    },

    getStatusColorClass() {
      const status = this.caseState.casefile.fileStatus;
      const colors = {
        NEW: "success",
        PEND: "warning",
        INVE: "info",
        COMP: "error",
      };
      return colors[status] || "grey";
    },

    newPendDisabled() {
      return !(
        (this.caseState.casefile.fileStatus == `NEW` ||
          this.caseState.casefile.fileStatus == `PEND`) &&
        this.management
      );
    },
    // assignAdjDisabled() {
    //   return !(this.caseState.casefile.fileStatus == `BRAC` && this.management);
    // },
    acceptAssignmentDisabled() {
      return !(
        this.caseState.casefile.fileStatus == `ADAC` &&
        this.caseState.casefile.adjuster == this.$store.state.auth.user.id
      );
    },

    isAddFileButtonDisabled() {
      return !(
        this.caseState.casefile.refType &&
        this.caseState.casefile.branch &&
        this.caseState.casefile.insurer &&
        this.caseState.casefile.claimNo &&
        this.caseState.casefile.vehicleNo &&
        this.caseState.casefile.dateOfLoss &&
        this.caseState.casefile.insComment
      );
    },
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YY") : "";
    },
    // assignAdjRule() {
    //   return !(
    //     // this.checkboxAcceptCase &&
    //     (this.branch.brCode && this.caseState.casefile.adjuster)
    //   );
    // },
    hasAvailableActions() {
      const status = this.caseState.casefile.fileStatus;
      const isAdjuster =
        this.caseState.casefile.adjuster === this.$store.state.auth.user.id;
      const isEditor =
        this.caseState.casefile.editor === this.$store.state.auth.user.id;
      const isClerk =
        this.caseState.casefile.clerkInCharge ===
        this.$store.state.auth.user.id;

      return {
        // Initial Processing
        showApplyDoc: status === "NEW" && this.management,
        showAssignBranch:
          (status === "NEW" || status === "PEND") && this.management,
        showBranchAccept: status === "BRAC" && this.management,

        // Investigation
        showAcceptAssignment: status === "ADAC" && isAdjuster,
        showCompleteInvestigation: status === "INVE" && isAdjuster,

        // Review & Edit
        showAssignEditor:
          (status === "PEDI" || status === "REIN") && this.management,
        showAcceptEditing: status === "EDAC" && isEditor,
        showCompleteEditing: status === "EDI" && isEditor,

        // Management
        showHoldApproval: status === "PAPP" && this.approvingManager,
        showApprovalReport: status === "HOLD" && this.holdingManager,
        showReInvestigate: status === "HOLD" && this.holdingManager,
        showReEdit: status === "HOLD" && this.holdingManager,

        // Final Processing
        showAssignClerk: status === "PFORM" && this.management,
        showAcceptFormatting: status === "CLERK" && isClerk,
        showCompleteFormatting: status === "FOIN" && isClerk,

        // Completion
        showInvoising: status === "INFI" && this.management,
        showDispatch: status === "DISP" && this.management,
        showDoneDispatch: status === "PDISP" && this.management,
        showClose: status === "DISP" && this.management,
      };
    },

    approvingManager() {
      return (
        this.$store.state.auth.user.roles.includes("ADMIN") ||
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("BRANCHMANAGER")
      );
    },
  },
  watch: {
    // assignAdj(val) {
    //   val || this.closeAdj();
    // },
    // assignBranch(val) {
    //   val || this.closeAssignBranchDialog();
    // },
    acceptCase(val) {
      val || this.closeAcceptCase();
    },
    "caseState.casefile": {
      immediate: true,
      handler() {
        this.preloadDocuments();
        this.preloadComments();
      },
      deep: true,
    },
  },

  methods: {
    moment: function (date) {
      return moment(date);
    },
    yearOnly(value) {
      // return moment(value).format("YYYY");
      return moment(value).format("YY");
    },
    filterRegions(item) {
      return this.regions.filter((region) => region.branchId === item);
    },
    filtersubRefType(item) {
      return this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === item
      );
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },
    filterAdjByBranch(item) {
      return this.adjusters.filter((r) =>
        r.branches.some((i) => i.brCode === item)
      );
    },

    filterClerkByBranch(item) {
      return this.clerks.filter((r) =>
        r.branches.some((i) => i.brCode === item)
      );
    },

    // findClerkByBranch(item) {
    //   return this.clerks.map((clerk) => {
    //     clerk.branches = clerk.branches.filter(
    //       (branch) => branch.brCode === item
    //     );
    //     // return clerk;
    //   });

    findClerkByBranch(item) {
      // if (!item) return 2;
      const myArray = this.clerks.filter((r) =>
        r.branches.some((i) => i.brCode === item)
      );
      return myArray.map((b) => b.email) || "sys.admin@associatedadjusters.com";
    },
    findAdjByBranch(item) {
      // if (!item) return 2;
      const myArray = this.adjusters.filter((r) =>
        r.branches.some((i) => i.brCode === item)
      );
      return myArray.map((b) => b.email) || "sys.admin@associatedadjusters.com";
    },
    findAdjEmail(item) {
      // console.log(item);
      const adjuster = this.adjusters.filter(
        (adjuster) => adjuster.id === item
      );
      return (
        adjuster.map((b) => b.email) || "sys.admin@associatedadjusters.com"
      );
    },
    // },
    // findAdjEmail(item) {
    //   console.log(item);
    //   const adjuster = this.adjusters.filter(
    //     (adjuster) => adjuster.id === item
    //   );
    //   console.log(adjuster);
    //   return adjuster.email;
    // },
    // findAdjEmail(item) {
    //   const myArray = this.adjusters.filter((r) =>
    //     r.branches.some((i) => i.id === item)
    //   );
    //   return myArray.map((b) => b.email) || "sys.admin@associatedadjusters.com";
    // },

    // filtersubRefTypeMap(item) {
    //   const myArray = this.subRefTypes.filter((subRef) => subRef.id === item);
    //   let result = myArray.map((a) => a.subCode);
    //   if (!item) return "";
    //   return result + "/";
    // },

    // filterClerkByBranch(item) {
    //   const myArray = this.clerks.filter((clerk) =>
    //     clerk.branches.some((i) => i.brCode === item)
    //   );
    //   console.log(myArray);
    //   let result = myArray.map((a) => a);
    //   if (!item) return "";
    //   return result + ";";
    // },

    filterStages(item) {
      return this.stages.filter((stage) => stage.stageCode === item);
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterBranchName(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch[0].name;
    },
    filterPicID(item) {
      if (!item) return "TBA";
      const filteredDept = this.refType.filter((dept) => dept.id === item);
      return filteredDept[0].picID;
    },
    filterAdj(item) {
      return this.adjusters.filter((adjuster) => adjuster.id === item);
    },
    filterEditor(item) {
      return this.editors.filter((editor) => editor.id === item);
    },
    filterrecipient(item) {
      if (!item) return "TBA";
      return this.clerks.filter((clerks) => clerks.id === item);
    },
    filterApprover(item) {
      return this.members.filter((approver) => approver.id === item);
    },
    filterClerk(item) {
      return this.clerks.filter((clerk) => clerk.id === item);
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    findBrcode(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );
      return filteredbranch[0].brCode;
      // return this.branches.filter((branch) => branch.id === item);
    },
    filterStates(item) {
      return this.states.filter((state) => state.id === item);
    },
    filterHandler(item) {
      return this.handlers.filter((handler) => handler.insId === item);
    },
    filterUser(item) {
      return this.members.filter((member) => member.id === item);
    },
    findUserEmail(item) {
      const user = this.members.filter((member) => member.id === item);
      return user[0] ? user[0].email : "sys.admin@associatedadjusters.com";
      // return user.map((b) => b.email);
    },
    currentUserBranch() {
      if (
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("ADMIN") ||
        this.$store.state.auth.user.roles.includes("CLERK")
      ) {
        return this.branches;
      } else
        return this.branches.filter(
          (x) => x.id === this.caseState.casefile.branch
        );
    },
    checkManagement() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHMANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHCLERK`) ||
        this.$store.state.auth.user.roles.includes(`CLERK`)
      ) {
        this.management = true;
      }
    },

    formatDate: function (date) {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY");
    },
    formatTime: function (date) {
      return moment(date).format("h:mm:ss a");
    },

    dateDiff(startDateString, endDateString) {
      // Method 01
      let start = moment(startDateString);
      let end = moment(endDateString);
      let duration = moment.duration(end.diff(start));
      return Math.round(duration.asDays());

      // Method 02
      // var start = moment(startDateString); //todays date
      // var end = moment(endDateString); // another date
      // var duration = moment.duration(end.diff(start));
      // var days = duration.asDays();
      // return Math.round(days);
    },
    refreshList() {
      this.retrieveAdjusters();
      this.retrieveEditors();
      this.retrieveClerks();
      this.retrieveUsers();
      this.retrieveBranches();
      this.retrieveStates();
      this.retrieveCasefiles(this.$route.params.id);
    },

    retrieveClaims() {
      ClaimDataService.getAll()
        .then((response) => {
          this.claims = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveDept() {
      DeptDataService.getAll()
        .then((response) => {
          this.refType = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveSubRefType() {
      SubRefTypeDataService.getAll()
        .then((response) => {
          this.subRefTypes = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveIns() {
      InsDataService.getAll()
        .then((response) => {
          this.insurer = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveHandler() {
      HandlerDataService.getAll()
        .then((response) => {
          this.handlers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveStages() {
      StagesDataService.getAll()
        .then((response) => {
          this.stages = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveAdjusters() {
      MemberDataService.getAll()
        .then((response) => {
          this.adjusters = response.data.filter(
            (item) =>
              item.roles.some((b) => b.roleCode === "adjuster") && item.active
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveEditors() {
      MemberDataService.getAll()
        .then((response) => {
          this.editors = response.data.filter(
            (item) =>
              item.roles.some(
                (b) =>
                  b.roleCode === "editor" ||
                  // b.roleCode === "clerk" ||
                  b.roleCode === "manager" ||
                  // b.roleCode === "branchclerk" ||
                  b.roleCode === "branchmanager"
              ) && item.active
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveClerks() {
      MemberDataService.getAll()
        .then((response) => {
          this.clerks = response.data.filter(
            (item) =>
              item.roles.some(
                (b) =>
                  b.roleCode === "clerk" ||
                  b.roleCode === "manager" ||
                  b.roleCode === "branchclerk" ||
                  b.roleCode === "branchmanager"
              ) && item.active
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveUsers() {
      MemberDataService.getAll()
        .then((response) => {
          this.members = response.data.filter((item) => item.active);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveBranches() {
      BranchDataService.getAll()
        .then((response) => {
          this.branches = response.data;
          // this.branches = response.data.map(this.getDisplayBranch);
          // this.branches = response.data.filter(
          //   (item) => item.roles[0].roleCode === "adjuster"
          // );
          // this.adjusters = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // getDisplayBranch(branch) {
    //   return {
    //     id: branch.id,
    //     name:
    //       branch.name.length > 30
    //         ? branch.name.substr(0, 30) + "..."
    //         : branch.name,
    //     brCode:
    //       branch.brCode.length > 30
    //         ? branch.brCode.substr(0, 30) + "..."
    //         : branch.brCode,
    //   };
    // },
    retrieveStates() {
      StateDataService.getAll()
        .then((response) => {
          this.states = response.data.map(this.getDisplayStates);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDisplayStates(state) {
      return {
        id: state.id,
        name:
          state.name.length > 30
            ? state.name.substr(0, 30) + "..."
            : state.name,
        stCode:
          state.stCode.length > 30
            ? state.stCode.substr(0, 30) + "..."
            : state.stCode,
      };
    },
    retrieveRegions() {
      RegionDataService.getAll()
        .then((response) => {
          this.regions = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveCasefiles(id) {
      this.loading = true;
      this.permissionCheckComplete = false;
      this.accessDenied = false;

      CasefileDataService.get(id)
        .then((response) => {
          // Store the casefile data before checking permissions
          const casefileData = response.data;

          // First make sure all dependencies are loaded before checking permissions
          Promise.all([this.ensureDeptLoaded(), this.ensureBranchesLoaded()])
            .then(() => {
              // After dependencies are loaded, check permissions
              if (!this.checkCasefileAccess(casefileData)) {
                this.accessDenied = true;
              } else {
                // User has access, proceed with loading data to store
                this.caseState.casefile = casefileData;
                this.checkFileaccess(); // Make sure this is called after the data is loaded
              }

              this.permissionCheckComplete = true;
              this.loading = false;
            })
            .catch(() => {
              // If there was an error loading dependencies, show access denied
              this.accessDenied = true;
              this.permissionCheckComplete = true;
              this.loading = false;
            });
        })
        .catch((e) => {
          console.log(e);
          // Handle API errors - might be access denied or not found
          this.accessDenied = true;
          this.permissionCheckComplete = true;
          this.loading = false;

          // Show a generic error message
          this.$store.dispatch("alerts/setAlert", {
            text: "Error loading case file data",
            type: "error",
          });
        });
    },

    ensureDeptLoaded() {
      if (this.refType && this.refType.length > 0) {
        return Promise.resolve();
      }

      return new Promise((resolve, reject) => {
        DeptDataService.getAll()
          .then((response) => {
            this.refType = response.data;
            resolve();
          })
          .catch((e) => {
            console.log("Error loading departments:", e);
            reject(e);
          });
      });
    },

    ensureBranchesLoaded() {
      if (this.branches && this.branches.length > 0) {
        return Promise.resolve();
      }

      return new Promise((resolve, reject) => {
        BranchDataService.getAll()
          .then((response) => {
            this.branches = response.data;
            resolve();
          })
          .catch((e) => {
            console.log("Error loading branches:", e);
            reject(e);
          });
      });
    },

    checkCasefileAccess(casefile) {
      if (!casefile) {
        return false;
      }

      const user = this.$store.state.auth.user;
      if (!user) {
        return false;
      }

      // Check for admin or manager role
      if (
        user.roles &&
        (user.roles.includes("ADMIN") ||
          user.roles.includes("MANAGER") ||
          user.roles.includes("CLERK"))
      ) {
        return true;
      }

      // Check if user is branch manager with access to this branch and department
      if (
        user.roles &&
        user.roles.includes("BRANCHMANAGER") &&
        user.branches &&
        Array.isArray(user.branches) &&
        user.branches.includes(casefile.branch) &&
        user.dept &&
        Array.isArray(user.dept) &&
        user.dept.includes(casefile.refType)
      ) {
        return true;
      }

      // Check if user is branch clerk with access to this branch and department
      if (
        user.roles &&
        user.roles.includes("BRANCHCLERK") &&
        user.branches &&
        Array.isArray(user.branches) &&
        user.branches.includes(casefile.branch) &&
        user.dept &&
        Array.isArray(user.dept) &&
        user.dept.includes(casefile.refType)
      ) {
        return true;
      }

      // Check if user is assigned adjuster
      if (casefile.adjuster === user.id) {
        return true;
      }

      // Check if user is assigned editor
      if (casefile.editor === user.id) {
        return true;
      }

      // Check if user is assigned clerk
      if (casefile.clerkInCharge === user.id) {
        return true;
      }

      // User doesn't have permission
      return false;
    },

    checkFileaccess() {
      // If we've already passed the permission check to get here,
      // we can simply set fileaccess to true
      this.fileaccess = true;
    },

    applyDoc() {
      this.dialogApplyDoc = true;
    },

    acceptCase() {
      this.dialogAcceptCase = true;
    },

    acceptCaseConfirm() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.fileStatus = `INVE`),
        (this.caseState.casefile.dateStartInv = moment().format())
      )
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "green",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeAcceptCase();
    },

    closeAcceptCase() {
      this.dialogAcceptCase = false;
      this.$nextTick(() => {
        // this.caseState.casefile.fileStatus = "ADAC";
      });
    },

    async preloadDocuments() {
      if (this.caseState.casefile.id) {
        this.documentsLoading = true;
        try {
          const response = await DocsDataService.getAll();
          // Filter documents for current casefile
          this.documents = response.data.filter(
            (doc) => doc.caseId === this.caseState.casefile.id
          );
          this.documentCount = this.documents.length;
        } catch (e) {
          console.log(e);
          this.documents = [];
          this.documentCount = 0;
        } finally {
          this.documentsLoading = false;
        }
      }
    },
    async preloadComments() {
      if (this.caseState.casefile.id) {
        this.commentsLoading = true;
        try {
          const response = await CommentsDataService.getAll();
          // Filter comments for current casefile
          this.comments = response.data.filter(
            (comment) => comment.caseId === this.caseState.casefile.id
          );
          this.commentCount = this.comments.length;
        } catch (e) {
          console.log(e);
          this.comments = [];
          this.commentCount = 0;
        } finally {
          this.commentsLoading = false;
        }
      }
    },
    fetchCommentCount() {
      if (this.caseState.casefile.id) {
        CommentsDataService.getAll()
          .then((response) => {
            const comments = response.data.filter(
              (comment) => comment.caseId === this.caseState.casefile.id
            );
            this.commentCount = comments.length;
          })
          .catch((e) => {
            console.log(e);
            this.commentCount = 0;
          });
      }
    },

    // fetchHistoryCount() {
    //   if (this.caseState.casefile.vehicleNo) {
    //     CasesDataService.getAll()
    //       .then((response) => {
    //         const historyCases = response.data.filter(
    //           (historyCase) =>
    //             historyCase.vehicleNo?.toLowerCase() ===
    //               this.caseState.casefile.vehicleNo?.toLowerCase() &&
    //             historyCase.id !== this.caseState.casefile.id
    //         );
    //         this.historyCount = historyCases.length;
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //         this.historyCount = 0;
    //       });
    //   }
    // },
    handleCommentAdded(newComment) {
      this.comments = [...this.comments, newComment];
      this.commentCount = this.comments.length;
    },

    handleCommentDeleted(commentId) {
      this.comments = this.comments.filter(
        (comment) => comment.id !== commentId
      );
      this.commentCount = this.comments.length;
    },
  },
  mounted() {
    this.overlay = false;

    // Load all required data in parallel first
    Promise.all([
      this.retrieveClaims(),
      this.retrieveAdjusters(),
      this.retrieveEditors(),
      this.retrieveClerks(),
      this.retrieveUsers(),
      this.retrieveBranches(),
      this.retrieveStates(),
      this.retrieveRegions(),
      this.retrieveDept(),
      this.retrieveSubRefType(),
      this.retrieveIns(),
      this.retrieveHandler(),
      this.retrieveStages(),
    ])
      .then(() => {
        // After all data is loaded, retrieve the casefile and check permissions
        this.retrieveCasefiles(this.$route.params.id);
        this.checkManagement();
        this.fetchCommentCount();
      })
      .catch((error) => {
        console.error("Error loading initial data:", error);
        this.loading = false;
        this.accessDenied = true;

        this.$store.dispatch("alerts/setAlert", {
          text: "Error loading initial data",
          type: "error",
        });
      });
  },
};
</script>
<style scoped>
.main-column {
  /* border-right: 1px solid green; */
  /* border-left: 1px solid green; */
  border-bottom: 1px solid grey;
  /* border-right: 1px solid grey; */
  /* border-left: 1px solid green; */
  /* background-color: rgb(214, 232, 233); */
  background-color: aliceblue;
}

.bottom-border {
  /* border-left: 1px solid blue; */
  border-bottom: 1px solid grey;
}

.header-section {
  background: linear-gradient(to right, #1976d2, #1565c0) !important;
  position: relative;
  color: white !important;
}

.header-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
}

.header-section .text-h5 {
  color: white !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font-size: 1.15rem !important;
}

.header-section .text-subtitle-1 {
  color: rgba(255, 255, 255, 0.9) !important;
}

.opacity-medium {
  opacity: 0.9;
}

.header-section .v-chip {
  font-weight: 600;
  color: white !important;
}

.flag-icon {
  transform: scale(1.2);
}

.flag-icon :deep(.v-btn--fab.v-size--x-small) {
  height: 32px;
  width: 32px;
}

.flag-icon :deep(.v-btn--fab.v-size--x-small .v-icon) {
  font-size: 20px;
}

.workflow-container {
  background: linear-gradient(to right, #f5f7fa, #e3e6e8);
  border-radius: 4px;
  margin: 8px;
}

workflow-stage {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.workflow-stage:last-child {
  border-right: none;
}

.stage-card {
  background: transparent !important;
  height: 100%;
}

.stage-header {
  color: #1976d2;
  font-weight: 500;
  font-size: 0.9rem;
  text-align: center;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
}

.action-button {
  transition: transform 0.2s;
}

.action-button:hover {
  transform: scale(1.1);
}

@media (max-width: 600px) {
  .workflow-stage {
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .workflow-stage:last-child {
    border-bottom: none;
  }

  .workflow-container {
    margin: 4px;
  }
}

.workflow-actions {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.workflow-title {
  border-bottom: 1px solid #e0e0e0;
  background: linear-gradient(to right, #f8f9fa, #ffffff);
}

.title-text {
  font-size: 1.5rem;
  font-weight: 500;
  color: #37474f;
}

.action-grid {
  gap: 16px;
}

.action-button {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  white-space: normal !important;
  color: white !important;
}

.action-button:hover {
  transform: translateY(-2px);
}

.action-text {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 4px;
  line-height: 1.2;
  text-align: center;
}

.status-chip {
  font-weight: 500;
  font-size: 0.875rem;
}

.no-actions {
  background: #f5f5f5;
  border-radius: 8px;
}

.action-wrapper {
  flex: 0 0 auto;
  width: calc(25% - 16px);
  margin: 8px;
  min-width: 250px;
}

@media (max-width: 1264px) {
  .action-wrapper {
    width: calc(33.33% - 16px);
  }
}

@media (max-width: 960px) {
  .action-wrapper {
    width: calc(50% - 16px);
  }
}

@media (max-width: 600px) {
  .action-wrapper {
    width: 100%;
    margin: 8px 0;
  }
}

/* Add styles for the access denied alert */
.v-alert.error {
  background-color: rgba(244, 67, 54, 0.1) !important;
}

.v-alert.error .v-alert__content {
  color: #f44336 !important;
}

.v-alert.error .caption {
  opacity: 0.7;
  margin-top: 8px;
}

/* Updated access denied card styles */
.access-denied-card {
  border: 2px solid #f44336;
  overflow: hidden;
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
}

.banner-top {
  font-size: 20px;
  position: relative;
}

.banner-top::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(
    to right,
    rgba(244, 67, 54, 0.7),
    rgba(244, 67, 54, 0.3)
  );
}

.v-alert.error {
  background-color: #ffebee !important;
}

.v-alert.error .v-alert__content {
  color: #d32f2f !important;
}

.access-denied-card .v-card__text {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}

/* Animation for the icon */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.access-denied-card .access-icon {
  animation: pulse 2s infinite ease-in-out;
}

/* Mobile responsive styles */
@media (max-width: 600px) {
  .access-denied-card {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    width: calc(100% - 16px);
  }

  .access-title {
    font-size: 1.25rem !important;
  }

  .access-message {
    font-size: 0.9rem !important;
  }

  .access-hint {
    font-size: 0.75rem !important;
  }

  .access-icon {
    font-size: 48px !important;
    height: 48px;
    width: 48px;
  }

  .return-button {
    width: 100%;
  }

  .v-card-text {
    padding: 16px !important;
  }
}

/* Tablet styles */
@media (min-width: 601px) and (max-width: 960px) {
  .access-denied-card {
    width: calc(100% - 24px);
    max-width: 550px;
  }

  .access-icon {
    font-size: 56px !important;
    height: 56px;
    width: 56px;
  }
}

/* Ensure the button text stays responsive */
.button-text {
  white-space: nowrap;
  font-weight: 500;
}
</style>
