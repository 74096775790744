<template>
  <div>
    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          :disabled="completeFormattingDisabled"
          class="success mr-1"
          fab
          x-small
          v-on="{ ...tooltip }"
          @click="completeFormatting()"
        >
          <v-icon>mdi-format-align-top</v-icon>
        </v-btn>
      </template>
      <span>COMPLETE FORMATTING</span>
    </v-tooltip> -->

    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-hover v-slot="{ hover }">
          <v-btn
            block
            height="80"
            class="success mr-1"
            :elevation="hover ? 8 : 2"
            v-on="{ ...tooltip }"
            :disabled="completeFormattingDisabled"
            @click="completeFormatting()"
          >
            <div class="d-flex flex-column align-center">
              <v-icon>mdi-format-align-top</v-icon>
              <span class="action-text">COMPLETE FORMATTING</span>
            </div>
          </v-btn>
        </v-hover>
      </template>
      <span>COMPLETE FORMATTING</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->
    <v-dialog v-model="dialogCompleteFormatting" max-width="750px">
      <v-card>
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span class="text-h5">COMPLETE FORMATTING</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-card width="100%" class="mt-3 mb-3 text-start">
                <v-card-title
                  >I have completed formatting the case report.</v-card-title
                >
                <!-- <v-list subheader two-line flat dense>
                  <v-subheader class="text-h6">I confirm that:</v-subheader>

                  <v-list-item-group multiple>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            v-model="checkboxCompFormatting"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Formatting</v-list-item-title>
                          <v-list-item-subtitle
                            >I have completed formatting the case
                            report.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                            v-model="checkboxCompFormatting1"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Documents</v-list-item-title>
                          <v-list-item-subtitle
                            >I have uploaded the updated case
                            report.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list> -->
                <v-card-actions>
                  <v-btn
                    tile
                    dark
                    small
                    @click="closeCompleteFormattingDialog"
                    class="mt-3 mr-2 error"
                  >
                    Cancel
                  </v-btn>
                  <v-btn tile small class="mt-3 success" @click="compFormatting"
                    >Submit</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "compFormatting",
  props: [""],
  components: {},

  data() {
    return {
      dialogCompleteFormatting: false,
      // checkboxCompFormatting: false,
      // checkboxCompFormatting1: false,
    };
  },

  computed: {
    completeFormattingDisabled() {
      return !(
        this.caseState.casefile.fileStatus == `FOIN` &&
        this.caseState.casefile.clerkInCharge == this.$store.state.auth.user.id
      );
    },

    ...mapState(["caseState"]),

    // isFileButtonDisabled() {
    //   return !(this.checkboxCompFormatting && this.checkboxCompFormatting1);
    // },
  },
  watch: {
    completeFormatting(val) {
      val || this.closeCompleteFormattingDialog();
    },
  },

  methods: {
    // moment: function (date) {
    //   return moment(date);
    // },

    completeFormatting() {
      this.dialogCompleteFormatting = true;
    },

    compFormatting() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.fileStatus = `INFI`),
        (this.caseState.casefile.dateEndFormatting = moment().format())
        // (this.caseState.casefile.registerBy = this.$store.state.auth.user.id),
        // (this.caseState.casefile.dateOfReg = moment().format())
      ).then((response) => {
        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "warning",
          text:
            (response && response.data && response.data.message) || toString(),
        });
      });

      this.closeCompleteFormattingDialog();
    },

    closeCompleteFormattingDialog() {
      this.dialogCompleteFormatting = false;
      this.$nextTick(() => {
        // this.caseState.casefile.fileStatus = "ADAC";
      });
    },
  },
};
</script>
