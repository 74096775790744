<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-hover v-slot="{ hover }">
          <v-btn
            block
            height="80"
            class="primary mr-1"
            :elevation="hover ? 8 : 2"
            v-on="{ ...tooltip }"
            :disabled="applyDocDisabled"
            @click="applyDoc()"
          >
            <div class="d-flex flex-column align-center">
              <v-icon>mdi-file-document-multiple</v-icon>
              <span class="action-text">APPLY DOCUMENT</span>
            </div>
          </v-btn>
        </v-hover>
      </template>
      <span>APPLY DOCUMENT</span>
    </v-tooltip>

    <!-- THE DIALOG BEGINS HERE -->
    <v-dialog v-model="dialogApplyDoc" max-width="500px">
      <v-card class="text-center">
        <v-card-title class="justify-center text-h5 grey lighten-2"
          >APPLY DOCUMENTS</v-card-title
        >
        <v-card-text class="mt-3 pa-0">
          CASEFILE NUMBER: AA<span
            v-for="(dept, index) in filterDept(caseState.casefile.refType)"
            :key="index"
            >/{{ dept.name
            }}<span
              v-for="(subDept, index) in filterSubDept(
                caseState.casefile.subRefType
              )"
              :key="index"
              >/{{ subDept.subCode }}</span
            ></span
          >/{{ caseState.casefile.id }}/{{
            yearOnly(caseState.casefile.createdAt)
          }}<span
            v-for="branch in filterBranches(caseState.casefile.branch)"
            :key="branch.id"
            >/{{ branch.brCode }}
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined small tile @click="dialogApplyDoc = false"
            >Cancel</v-btn
          >
          <v-btn small tile class="success" @click="setPendingDoc">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "applyDoc",
  props: ["management", "refType", "subRefTypes", "branches"],
  components: {},

  data() {
    return {
      dialogApplyDoc: false,
    };
  },

  computed: {
    applyDocDisabled() {
      return !(this.caseState.casefile.fileStatus == `NEW` && this.management);
    },
    ...mapState(["caseState"]),
  },
  watch: {
    applyDoc(val) {
      val || this.closeApplyDoc();
    },
  },

  methods: {
    moment: function (date) {
      return moment(date);
    },
    yearOnly(value) {
      return moment(value).format("YY");
    },
    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRefType) => subRefType.id === item);
    },
    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },

    applyDoc() {
      this.dialogApplyDoc = true;
    },

    setPendingDoc() {
      CasefileDataService.update(
        this.caseState.casefile.id,
        this.caseState.casefile,
        (this.caseState.casefile.fileStatus = `PEND`),
        (this.caseState.casefile.applyDocBy = this.$store.state.auth.user.id),
        (this.caseState.casefile.applyDocDate = moment().format())
      )
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "warning",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
        })
        .catch((e) => {
          console.log(e);
        });

      this.closeApplyDoc();
    },

    closeApplyDoc() {
      this.dialogApplyDoc = false;
      this.$nextTick(() => {});
    },
  },
  mounted() {},
};
</script>
